import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Color } from 'ng2-charts/ng2-charts';
import { ApiService } from 'src/app/api.service';
import { Qualification } from 'src/app/qualification';
import * as moment from 'moment';
import { ApiConfig } from "../../../ApiConfig";
import { SearchbarService } from '../../Components/searchbar/searchbarservice.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-cloture',
  templateUrl: './cloture.component.html',
  styleUrls: ['./cloture.component.sass']
})
export class ClotureComponent implements OnInit, OnDestroy {

  heading = 'Dossiers finalisées';
  analytics = false;
  retour = true;
  currentDate = new Date();
  formattedDate = (moment(this.currentDate)).format('DD-MM-YYYY');
  subheading = `Dernière mise à jour : ${this.formattedDate}`;
  page: any = 1;
  pageSize = 15;
  previousPage: any;
  termines: any;
  terminesNumber: any;
  urlPhotoColab = './assets/images/avatars/';
  dossierId: any ;

  @Input() ficheNumberDefault;
  statutTbd = "TERMINE";
  ficheNumber: any;
  subscription: Subscription;
  fiche: any;
  subscription2: Subscription;
  searchTermExist: any = false;
  subscription3: Subscription;
  searchTermValue: any;
  oldsearchTermValue: any;
  subscription4: Subscription;
  paginationOffsetChange: any;
  subscription5: Subscription;


  constructor(private apiService: ApiService, public activeRoute: ActivatedRoute, public router: Router,  private data: SearchbarService) {}


  ngOnInit() {
    this.analytics = true;
    this.apiService.readAllTerminesNumber().subscribe((terminesNumber: any) => {
      this.terminesNumber = terminesNumber;
      console.log(this.terminesNumber);

    });
    this.apiService.readAllTermines().subscribe((termines: Qualification[]) => {
      this.termines = termines;
      console.log(this.termines);
    });

    this.subscription = this.data.currentficheNumber .subscribe(ficheNumber => this.terminesNumber = ficheNumber);
    this.subscription2 = this.data.currentfiche.subscribe(fiche => this.termines = fiche);
    this.subscription3 = this.data.currentsearchTermSource.subscribe(searchTermExist => this.searchTermExist = searchTermExist);
    this.subscription4 = this.data.currentsearchTermValue.subscribe(searchTermValue => this.searchTermValue  = searchTermValue );
    this.subscription5 = this.data.currentpaginationOffset.subscribe(paginationOffsetChange => this.page  = paginationOffsetChange);
  }

  getDossier(id) {
    this.apiService.getDossier(id).subscribe((qualif: Qualification[]) => {
      this.dossierId = id;
      this.apiService.DossierId = this.dossierId;
      localStorage.setItem("currentDossier", JSON.stringify(id));
      const myurl = `${"/dashboard/anomalie/"}${id}`;
      this.router.navigate([myurl]);
      this.ngOnInit();
    });
  }

  getRedirection(id) {
    // if ( this.mandant) {
    //   window.open(`${ApiConfig.redirectionExtranet}/${id}`, '_blank');
    // } else {
      window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
    // }
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.loadData(this.previousPage);
    }
  }

  loadData(page: number) {

    const offset = page * 15 - 15;
    console.log(offset);


    if (!this.searchTermExist) {
      this.apiService.readAllTerminesPage(offset).subscribe((termines: any) => {
        this.termines = termines ;
      }, (error: any) => {
      console.log(error) ;
      });
    } else {
      this.apiService.readAllPageSearch(offset, this.statutTbd, this.searchTermValue).subscribe((ficheSearch: any) => {
        this.termines = ficheSearch;
      }, (error: any) => {
      console.log(error) ;
      });

    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscription4.unsubscribe();
    this.subscription5.unsubscribe();
  }
}
