import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../../../api.service';
import { Qualification } from '../../../../../qualification';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit {

  public isActive: any;
  dossierId: any ;
  searchDossier: string;
  qualifications: Qualification[];
  userCredentials: any;
  mandant: boolean;
  filterName: any;
  errorDossier: boolean;

  constructor(private apiService: ApiService, public activeRoute: ActivatedRoute, public router: Router) { }

  getNumeroDossier(numeroDossier) {
    if (!numeroDossier) {
      console.log('pas de numero');
    } else {
      this.apiService.getDossierByNumero(numeroDossier).subscribe((qualif: Qualification[]) => {
        if ( qualif.length <= 0 ) {
          console.log('Mauvais numero');
          this.errorDossier = true;
          setTimeout(() => {
            this.errorDossier = false;
          }, 3500);
        } else {
        this.dossierId = qualif[0].id;
        this.apiService.DossierId = this.dossierId;
        localStorage.setItem("currentDossier", JSON.stringify(this.dossierId ));
        const myurl = `${"/dashboard/anomalie/"}${this.dossierId}`;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate([myurl])
        );
        this.errorDossier = true;
        }
      });
    }
    this.filterName = '';
  }

  getNumeroSinistre(numeroSinistre) {
    if (!numeroSinistre) {
      console.log('pas de numero');
    } else {
      this.apiService.getDossierBySinistre(numeroSinistre).subscribe((qualif: Qualification[]) => {
        if ( qualif.length <= 0 ) {
          console.log('Mauvais numero');
          this.errorDossier = true;
          setTimeout(() => {
            this.errorDossier = false;
          }, 3500);
        } else {
        this.dossierId = qualif[0].id;
        this.apiService.DossierId = this.dossierId;
        localStorage.setItem("currentDossier", JSON.stringify(this.dossierId ));
        const myurl = `${"/dashboard/anomalie/"}${this.dossierId}`;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate([myurl]));
        this.errorDossier = true;
        }
      });
    }
    this.filterName = '';
  }

  getNumeroFiche(numeroDossier) {
    this.apiService.getDossier(numeroDossier).subscribe((qualif: Qualification[]) => {
    // this.dossierId = qualif;
    // this.apiService.DossierId = this.dossierId;
    // const myurl = `${"/dashboard/anomalie/"}${id}`;
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    // this.router.navigate([myurl]));
      this.apiService.DossierId = numeroDossier;
      const myurl = `${"/dashboard/anomalie/"}`;
      this.router.navigate([myurl]);
      this.ngOnInit();
  });
  }

  closeError() {
    this.errorDossier = false;
  }

  ngOnInit() {
    this.userCredentials = this.apiService.currentUserValue;
    if ( this.userCredentials.profil === "Collaborateur" ) {
      this.mandant = false;
    } else {
      this.mandant = true;
    }
  }


}
