import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import * as moment from 'moment';

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [
  {
    type: "success",
    message: "Votre demande à bien été créée",
  },
];

@Component({
  selector: "app-demandequalite",
  templateUrl: "./demandequalite.component.html",
  styleUrls: ["./demandequalite.component.sass"],
})
export class DemandequaliteComponent implements OnInit {
  private routeSub: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute
  ) {
    this.reset();
  }

  // AutoComplete
  opportunityControl = new FormControl();
  // filteredOptions: Observable<string[]>;
  // options = ['81923', '57430', '54552', '81923', '57430', '54552', '81923', '57430', '54552', '81923'];

  // Upload
  selectedFiles: FileList;
  progressInfos = [];
  message = "";
  fileInfos: Observable<any>;
  //

  heading = "Création d'une nouvelle demande";
  analytics = false;
  retour = true;
  okmandant = true;
  currentDate = new Date();
  formattedDate = (moment(this.currentDate)).format('YYYY-MM-DD');
  subheading = `Dernière mise à jour : ${this.formattedDate}`;
  page = 3;
  getProfil$: Subscription;
  selectOpportunityForm: FormGroup;
  createInfoForm: FormGroup;
  createAffectationForm: FormGroup;
  createTraitementForm: FormGroup;
  createPoleForm: FormGroup;
  mandant: boolean;
  compagnieassurance: boolean;
  etapeTroisValide: boolean;
  affectok: boolean;
  wrongNumber: boolean;
  showfield: boolean;
  activeIdString: any;
  qualifications: any;
  optionsMotifs: any;
  optionsMotifsCollab: any;
  optionsEntreprises: any;
  collabsQualite: any;
  collabsDynaren: any;
  serviceDynaren: any;
  userCredentials: any;
  opportunity: any;
  ficheNumberTempId: number;
  NumeroDossier: number;
  formData = [];
  alerts: Alert[];
  profilConnected = [];
  opportunityConcerne;
  ServicesAffectes: any;
  numeroSinistreAuto: any;

  ngOnInit() {
    // Autocomplete

    // this.filteredOptions = this.opportunityControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value))
    // );


    this.routeSub = this.activeRoute.params.subscribe((params) => {
      if (params['id']) {
        this.numeroSinistreAuto = params['id'];
        // Redirection automatique selection du sinistre
        this.opportunityControl.setValue(this.numeroSinistreAuto);
        this.selectSinistre();
      } else {
        console.log('Pas de numéro de sinistre auto');
      }
  });

    // Profil

    this.analytics = true;
    this.activeIdString = "1";
    this.userCredentials = this.apiService.currentUserValue;
    if ( this.userCredentials.profil === "Collaborateur" ) {
      this.mandant = false;
    } else if (this.userCredentials.profil === "Compagnie d'assurance") {
      this.mandant = true;
      this.compagnieassurance = true;
      console.log('Compagnie dassurance');
    } else if (this.userCredentials.profil === "Agent") {
      this.mandant = true;
      console.log('Agent');
    } else if (this.userCredentials.profil === "Cabinet d'experts") {
      this.mandant = true;
      console.log('Cabinet dexperts');
    } else if (this.userCredentials.profil === "Entreprise") {
      this.mandant = true;
      console.log('Entreprise');
    } else if (this.userCredentials.profil === "Intermédiaire d'assurance") {
      this.mandant = true;
      console.log('Intermédiaire dassurance');
    } else if (this.userCredentials.profil === "Autre") {
      this.mandant = true;
      console.log('Autre');
    } else {
       this.mandant = true;
     }
    this.getProfil$ = this.apiService
      .getActiveProfil(this.userCredentials.id)
      .subscribe((data) => {
        this.profilConnected.push(data);
      });


    // GEt Form suivipar, affectea, motifsreclamation, entreprise

    // this.collabsQualite = this.apiService.collabsQualite;
    this.apiService.getCollabQualite().subscribe((collabsQualite: any[]) => {
      this.collabsQualite = collabsQualite;
    });

    // this.optionsMotifs = this.apiService.optionsMotifs;
    this.apiService.getMotifs().subscribe((motifs: any[]) => {
      this.optionsMotifs = motifs;
    });

    // this.optionsMotifsCollab = this.apiService.optionsMotifsCollab;
    this.apiService.getMotifsCollab().subscribe((motifsCollab: any[]) => {
      this.optionsMotifsCollab  = motifsCollab;
    });

    // this.optionsEntreprises = this.apiService.optionsEntreprises;
    this.apiService.getEntreprises().subscribe((entreprises: any[]) => {
      this.optionsEntreprises = entreprises;
    });

    // this.collabsDynaren = this.apiService.collabsDynaren;
    this.apiService.getCollabDynaren().subscribe((collabsDynaren: any[]) => {
      this.collabsDynaren  = collabsDynaren;
    });

    // this.serviceDynaren = this.apiService.serviceDynaren;
    this.apiService.getServiceDynaren().subscribe((serviceDynaren: any[]) => {
      this.serviceDynaren = serviceDynaren;
    });


    this.selectOpportunityForm = this.formBuilder.group({
      opportunityid: [""],
    });

    this.createInfoForm = this.formBuilder.group({
      createdby: [
        this.userCredentials.firstname + " " + this.userCredentials.lastname,
      ],
      opportid: [""],
      insurantlastname: [""],
      insurantfirstname: [""],
      concernesocietename: [""],
      disasterdate: [""],
      disastertypeid: [""],
      dysfonctionnementslibelle: [""],
      opportunityanimateuranimateur: [""],
      opportunitynsinistrecompagniename: [""],
      opportunityregion: [""],
      opportunitydepartement: [""],
      urgence: [""],
      file: [""],
      description: [""],
      mandant: [false]
    });

    this.createAffectationForm = this.formBuilder.group({
      affectea: [""],
    });

    this.createTraitementForm = this.formBuilder.group({
      serviceConcerne: [""],
      suivipar: [""],
    });

    this.createPoleForm = this.formBuilder.group({
      suiviservice: [""],
    });

    this.apiService
      .getOpportunity(this.selectOpportunityForm.value)
      .subscribe((opportunity: any[]) => {
        this.opportunity = opportunity;
        if (this.opportunity.length <= 0) {
          this.showfield = false;
        } else {
          this.showfield = true;
        }
    });

    this.apiService.getServicesAffectes().subscribe((ServicesAffectes: any[]) => {
      this.ServicesAffectes = ServicesAffectes;
    });
  }




  // Autocomplete
  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  // }

  // Send Form

  selectOpportunity() {
    console.log(this.opportunityControl.value);

    this.apiService
      .getOpportunity(this.opportunityControl.value)
      .subscribe((opportunity: any[]) => {
        // this.opportunity = opportunity[0];
        // this.opportunityConcerne = opportunity[0].autressocietes;
        // if ( opportunity.length === 0) {
        //   this.wrongNumber = true;
        //   this.showfield = false;
        // }
        // if (this.opportunity.length <= 0) {
        //   this.showfield = false;
        // } else {
        //   this.showfield = true;
        //   this.wrongNumber = false;
        // }


        if ( opportunity.length === 0) {
          this.wrongNumber = true;
          this.showfield = false;
        } else {
          this.opportunityConcerne = opportunity[0].autressocietes;
          this.opportunity = opportunity[0];
          this.showfield = true;
          this.wrongNumber = false;
        }

      }, error => {
        console.log('erreur');
        this.wrongNumber = true;
        this.showfield = false;
      }, );
  }

  selectSinistre() {
    this.apiService
      .getNSinistre(this.opportunityControl.value)
      .subscribe((opportunity: any[]) => {
        // this.opportunityConcerne = opportunity[0].autressocietes;
        // this.opportunity = opportunity[0];
        // if (this.opportunity.length <= 0) {
        //   this.showfield = false;
        // }

        if ( opportunity.length === 0) {
          this.wrongNumber = true;
          this.showfield = false;
        } else {
          this.opportunityConcerne = opportunity[0].autressocietes;
          this.opportunity = opportunity[0];
          this.showfield = true;
          this.wrongNumber = false;
        }
      }, error => {
        console.log('erreur');
        this.wrongNumber = true;
        this.showfield = false;
      }, );
  }

  createInfo() {
    this.apiService
      .createInfo(this.createInfoForm.value)
      .subscribe((result) => {
        this.ficheNumberTempId = result.id;
        if ( this.selectedFiles) {
          this.uploadFiles(this.ficheNumberTempId);
        }
        if (result) {
          if ( this.mandant === false) {
            this.activeIdString = "2";
          }
          if ( this.mandant === true) {
            this.etapeTroisValide = true;
            setTimeout(() => {
              const myurl = `${'/dashboard/home'}`;
              this.router.navigate([myurl]); }, 3000);
          }
        }
      });
  }

  // createPole() {
  //   this.apiService
  //     .createPole(this.ficheNumberTempId, this.createPoleForm.value)
  //     .subscribe((result) => {
  //     });
  //   this.activeIdString = "3";
  // }

  createAffectation() {
    this.apiService
      .createAffectation(this.ficheNumberTempId, this.createAffectationForm.value)
      .subscribe((result) => {});
    // this.activeIdString = "3";
    this.etapeTroisValide = true;
    setTimeout(() => {
      const myurl = `${'/dashboard/home'}`;
      this.router.navigate([myurl]); }, 3000);
  }

  // createTraitement() {
  //   this.apiService
  //     .createSuivi(this.ficheNumberTempId, this.createTraitementForm.value)
  //     .subscribe((result) => {});
  //   this.etapeTroisValide = true;
  //   setTimeout(() => {
  //     const myurl = `${'/dashboard/home'}`;
  //     this.router.navigate([myurl]); }, 3000);
  // }


  // Message success

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  reset() {
    this.alerts = Array.from(ALERTS);
  }

   // Upload Files

   selectFiles(event) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  uploadFiles(id) {
    this.message = '';
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i], id);
    }
  }

  upload(idx, file, id) {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    this.apiService.uploadCreate(file, id).subscribe(
      event => {
        if (event) {
          this.progressInfos[idx].value = 100;
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        this.message = 'Could not upload the file:' + file.name;
      });
  }

}
