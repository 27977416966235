import { Component, Input } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent {

  constructor(private location: Location){}


  @Input() heading;
  @Input() subheading;
  @Input() icon;
  @Input() analytics;
  @Input() qualite;
  @Input() autre;
  @Input() retour;


  backClicked() {
    this.location.back();
  }

}
