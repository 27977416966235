import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakline'
})
export class BreaklinePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var regex = regex = /(\\r\\n)/gm;
    value = value.replace(regex, "");
    //value = value.replace(/(<([^>]+)>)/gi, "");//striptags
    return value;
  }

}
