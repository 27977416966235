import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Color } from 'ng2-charts/ng2-charts';
import { ApiService } from '../../../api.service';
import { Qualification } from '../../../qualification';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiConfig } from "../../../ApiConfig";
import { SearchbarService } from '../../Components/searchbar/searchbarservice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-retourdo',
  templateUrl: './retourdo.component.html',
  styleUrls: ['./retourdo.component.sass']
})
export class RetourdoComponent implements OnInit, OnDestroy {

  heading;
  mandant;
  analytics = false;
  retour = true;
  currentDate = new Date();
  formattedDate = (moment(this.currentDate)).format('DD-MM-YYYY');
  subheading = `Dernière mise à jour : ${this.formattedDate}`;
  page: any = 1;
  pageSize = 15;
  previousPage: any;
  dos: any;
  doNumber: any;
  urlPhotoColab = './assets/images/avatars/';
  dossierId: any ;
  userCredentials: any;

  @Input() ficheNumberDefault;
  statutTbd = "ATT_DO";
  ficheNumber: any;
  subscription: Subscription;
  fiche: any;
  subscription2: Subscription;
  searchTermExist: any = false;
  subscription3: Subscription;
  searchTermValue: any;
  oldsearchTermValue: any;
  subscription4: Subscription;
  paginationOffsetChange: any;
  subscription5: Subscription;

  constructor(private apiService: ApiService, public activeRoute: ActivatedRoute, public router: Router, private data: SearchbarService) {}

  ngOnInit() {
    this.analytics = true;

    this.userCredentials = this.apiService.currentUserValue;
    if (this.userCredentials.profil === "Collaborateur") {
      this.mandant = false;
      this.heading = 'Demandes en attente retour DO';

      this.apiService.readAllDoNumber().subscribe((doNumber: any) => {
        this.doNumber = doNumber;
      });

      this.apiService.readAllDo().subscribe((dos: Qualification[]) => {
        this.dos = dos;
      });
    } else {
      this.mandant = true;
      this.heading = 'Réclamations en attente de votre retour';

      this.apiService.readAllDoMandantNumber(this.userCredentials.clientcompany.id).subscribe((doNumber: any) => {
        this.doNumber = doNumber;
      });

      this.apiService.readAllDoMandant(this.userCredentials.clientcompany.id).subscribe((dos: Qualification[]) => {
        this.dos = dos;
      });
    }
    this.subscription = this.data.currentficheNumber .subscribe(ficheNumber => this.doNumber = ficheNumber);
    this.subscription2 = this.data.currentfiche.subscribe(fiche => this.dos = fiche);
    this.subscription3 = this.data.currentsearchTermSource.subscribe(searchTermExist => this.searchTermExist = searchTermExist);
    this.subscription4 = this.data.currentsearchTermValue.subscribe(searchTermValue => this.searchTermValue  = searchTermValue );
    this.subscription5 = this.data.currentpaginationOffset.subscribe(paginationOffsetChange => this.page  = paginationOffsetChange);
  }

  getDossier(id) {
    this.apiService.getDossier(id).subscribe((qualif: Qualification[]) => {
      this.dossierId = id;
      this.apiService.DossierId = this.dossierId;
      localStorage.setItem("currentDossier", JSON.stringify(id));
      const myurl = `${"/dashboard/anomalie/"}${id}`;
      this.router.navigate([myurl]);
      this.ngOnInit();
    });
  }

  getRedirection(id) {
    if ( this.mandant) {
      window.open(`${ApiConfig.redirectionExtranet}/${id}`, '_blank');
    } else {
      window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
    }
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.loadData(this.previousPage);
    }
  }

  loadData(page: number) {

    const offset = page * 15 - 15;
    console.log(offset);
    if (this.userCredentials.profil === "Collaborateur") {if (!this.searchTermExist) {
      this.apiService.readAllDoPage(offset).subscribe((dos: any) => {
        this.dos = dos;
      }, (error: any) => {
      console.log(error) ;
      });
    } else {
      this.apiService.readAllPageSearch(offset, this.statutTbd, this.searchTermValue).subscribe((ficheSearch: any) => {
        this.dos = ficheSearch;
      }, (error: any) => {
      console.log(error) ;
      });

    }
    } else {
      this.apiService.readAllDoMandantPage(offset, this.userCredentials.clientcompany.id).subscribe((dos: any) => {
        this.dos = dos;
      }, (error: any) => {
      console.log(error) ;
      });

    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscription4.unsubscribe();
    this.subscription5.unsubscribe();
  }

}

