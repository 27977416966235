import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Qualification } from "./qualification";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { ApiConfig } from "./ApiConfig";
import { environment } from "./../environments/environment";

let httpHeaders = new HttpHeaders({
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
});

const options = {
  headers: httpHeaders,
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  DossierId: number;
  FicheId: number;
  activeprofil: number;
  redirectUrl: string;
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  dataUser: any;
  password: any;
  optionsEntreprises;
  optionsAnimateurs;
  optionsMandants;
  optionsEmetteurs;
  collabsQualite;
  optionsMotifs;
  optionsMotifsCollab;
  collabsDynaren;
  serviceDynaren;
  usercurrent;
  usersession;
  createFiche;
  dataPreprod = {
    grant_type: environment.grantType,
    client_id: environment.clientId,
    client_secret: environment.clientSecret,
    username: environment.username,
    password: environment.password,
  };
  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("CurrentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // GLOBAL METHODS

  public get currentUserValue() {
    if (this.activeprofil) {
      this.activeprofil = this.usercurrent.id;
      return this.usercurrent;
    }
    if (!this.activeprofil) {
      this.activeprofil = this.usercurrent.id;
      localStorage.setItem("Session", JSON.stringify(this.usersession));
      this.currentUserSubject.next(this.usersession);
      httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.usersession.access_token}`,
      });
      return this.usercurrent;
    }
  }

  // Token
  setToken(token: string) {
    localStorage.setItem("token", token);
  }
  setUser(token: string) {
    localStorage.setItem("token", token);
  }
  getToken() {
    return localStorage.getItem("token");
  }

  getUsers() {
    return localStorage.getItem("CurrentUser");
  }

  getSession() {
    return localStorage.getItem("Session");
  }

  deleteToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("CurrentUser");
    localStorage.removeItem("Session");
  }
  isLoggedIn() {
    const usertoken = this.getToken();
    this.usercurrent = JSON.parse(this.getUsers());
    this.usersession = JSON.parse(this.getSession());
    if (usertoken != null) {
      return true;
    }
    return false;
  }

  // ConnexionApi Authentification

  public connexionApi(): Observable<any> {
    return this.httpClient
      .post<any>(ApiConfig.connexion, this.dataPreprod, options)
      .pipe(
        map((token) => {
          if (token && token.access_token) {
            localStorage.setItem("Session", JSON.stringify(token));
            this.currentUserSubject.next(token);
            httpHeaders = new HttpHeaders({
              Authorization: `Bearer ${token.access_token}`,
            });
          }
          return token;
        })
      );
  }

  public userlogin(log, pass) {
    const userLog = { login: log, password: pass };
    return this.httpClient
      .post<any>(ApiConfig.authentification, userLog, {
        headers: httpHeaders,
      })
      .pipe(
        map((Users) => {
          this.setUser(Users.lastname);
          localStorage.setItem("CurrentUser", JSON.stringify(Users));
          this.currentUserSubject.next(Users);
          this.getLoggedInName.emit(true);
          return Users;
        })
      );
  }

  public userAlreadylogin(idconnect) {
    const userLog = { identifiantContact: idconnect };
    return this.httpClient
      .post<any>(ApiConfig.authentification, userLog, {
        headers: httpHeaders,
      })
      .pipe(
        map((Users) => {
          this.setUser(Users.lastname);
          localStorage.setItem("CurrentUser", JSON.stringify(Users));
          this.currentUserSubject.next(Users);
          this.getLoggedInName.emit(true);
          return Users;
        })
      );
  }

  getActiveProfil(id: number): Observable<any> {
    if (id) {
      this.activeprofil = id;
      return this.httpClient.get<any[]>(`${ApiConfig.getUserById}/${id}`, {
        headers: httpHeaders,
      });
    }
    if (id === null) {
    }
  }

  // GetFiches

  readAll(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllNumber(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  // FQ a qualifier

  readQualifications(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllQualificationsNumber(): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllQualifications(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllQualificationsPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }
  // => FQ a qualifier cisibilite Mandant
  readQualificationsMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllQualificationsMandantNumber(id): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllQualificationsMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllQualificationsMandantPage(offset, id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  // FQ Attente retour des services

  readServices(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        // format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllServicesNumber(): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllServices(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        // format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllServicesPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        // format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  // => FQ Attente retour des services visibilite Mandant
  readServicesMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllServicesMandantNumber(id): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllServicesMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllServicesMandantPage(offset, id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  // FQ Attente retour DO

  readDo(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        // format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllDoNumber(): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllDo(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllDoPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }


  // => FQ Attente retour Do Mandant

  readDoMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllDoMandantNumber(id): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllDoMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllDoMandantPage(offset, id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }


  // FQ Attente de finalisation

  readFinalisation(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllFinalisationNumber(): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL"
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllFinalisation(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        // format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllFinalisationPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        // format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  // => FQ Attente finalisation Mandant

  readFinalisationMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 5,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllFinalisationMandantNumber(id): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllFinalisationMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllFinalisationMandantPage(offset, id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  // FQ Terminées

  readTermines(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "TERMINE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        limit: 10,
        sorts: {
          modifiedat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllTerminesNumber(): Observable<any> {
    return this.httpClient.post<any>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "TERMINE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }


  readAllTermines(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "TERMINE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 15,
        sorts: {
          modifiedat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllTerminesAnalytics(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "TERMINE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        sorts: {
          modifiedat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllTerminesPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "TERMINE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllTerminesMandant(id): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "TERMINE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          "opportunity.clientcompany": id,
        },
        // format: "flat",
        limit: 20,
        sorts: {
          modifiedat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  // Get Fiches Qualité par utilisateur connecté


  readDemandesNumber(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          "statut NOT IN": "ABAND",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          affectea: this.activeprofil,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandes(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          "statut NOT IN": "ABAND",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          "statut NOT IN": "ABAND",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }


  readDemandesQualificationNumber(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesQualification(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesQualificationPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ENCOURS",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesDONumber(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesDO(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesDOPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_DO",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesServiceNumber(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesService(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesServicePage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "ATT_SERVICE",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesFinalisationNumber(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesFinalisation(): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readDemandesFinalisationPage(offset): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          statut: "EN_FIN",
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          // suivipar: this.activeprofil,
          affectea: this.activeprofil,
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readInsatisfactionsNumber(forms): Observable<any[]> {

    if (forms.statutcode === 'null') {
      forms.statutcode = null;
    }

    if (forms.debutperiode === '') {
      forms.debutperiode = null;
    }

    if (forms.finperiode === '') {
      forms.finperiode = null;
    }

    return this.httpClient.post<any[]>(
      ApiConfig.getInsatisfactions, {
        limit: 15,
        traitement: forms.statutcode,
        dateDebut: forms.debutperiode,
        dateFin: forms.finperiode,
        sorts: {
          date_reception: "DESC"
        }
      }, { headers: httpHeaders }
    );
  }

  readInsatisfactionsTraiteeNumber(): Observable<any[]> {

    return this.httpClient.post<any[]>(
      ApiConfig.getInsatisfactions, {
        limit: 15,
        traitement: true,
      }, { headers: httpHeaders }
    );
  }

  readInsatisfactionsNonTraiteeNumber(): Observable<any[]> {

    return this.httpClient.post<any[]>(
      ApiConfig.getInsatisfactions, {
        limit: 15,
        traitement: false,
      }, { headers: httpHeaders }
    );
  }



  readInsatisfactions(forms): Observable<any[]> {

    if (forms.statucode === 'null') {
      forms.statutcode = null;
    }

    if (forms.debutperiode === '') {
      forms.debutperiode = null;
    }

    if (forms.finperiode === '') {
      forms.finperiode = null;
    }
    return this.httpClient.post<any[]>(
      ApiConfig.getInsatisfactions, {
        limit: 15,
        traitement: forms.statutcode,
        dateDebut: forms.debutperiode,
        dateFin: forms.finperiode,
        sorts: {
          date_reception: "DESC"
        }
      }, { headers: httpHeaders }
    );
  }

  readInsatisfactionsPage(offset, forms): Observable<any[]> {

    if (forms.statutcode === 'null') {
      forms.statutcode = null;
    }

    if (forms.debutperiode === '') {
      forms.debutperiode = null;
    }

    if (forms.finperiode === '') {
      forms.finperiode = null;
    }

    return this.httpClient.post<any[]>(
      ApiConfig.getInsatisfactions,
      {
        limit: 15,
        traitement: forms.statutcode,
        dateDebut: forms.debutperiode,
        dateFin: forms.finperiode,
        page: offset,
        sorts: {
          date_reception: "DESC"
        }
      }, { headers: httpHeaders }
    );
  }

  readAllInsatisfactions(): Observable<any[]> {
    return this.httpClient.post<any[]>(
      ApiConfig.getInsatisfactions, {
        limit: 2000,
        sorts: {
          date_reception: "DESC"
        }
      }, { headers: httpHeaders }
    );
  }




  getDossier(id: number): Observable<any> {
    return this.httpClient.get<any[]>(`${ApiConfig.getDossierById}/${id}`, {
      headers: httpHeaders,
    });
  }

  getDossierByNumero(clientCompany): Observable<Qualification[]> {
    if (clientCompany) {
      return this.httpClient.post<Qualification[]>(
        ApiConfig.getAllFiche,
        {
          filters: {
            nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
            opportunity: clientCompany,
          },
        },
        { headers: httpHeaders }
      );
    }
  }

  getDossierBySinistre(clientCompanySinistre): Observable<Qualification[]> {
    if (clientCompanySinistre) {
      return this.httpClient.post<Qualification[]>(
        ApiConfig.getAllFiche,
        {
          filters: {
            nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
            "opportunity.nsinistrecompagnie": clientCompanySinistre,
          },
        },
        { headers: httpHeaders }
      );
    }
  }

  getCollabQualite(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getCollabQualite,
      {
        filters: {
          groupnamecrm: "Service Qualité",
          accountstatus: "Interne",
        },
        sorts: {
          lastname: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getServiceDynaren(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getServiceDynaren,
      {
        filters: {
          active: "Oui",
        },
        sorts: {
          libelle: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getCollabDynaren(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getCollabDynaren,
      {
        filters: {
          active: "Oui",
          clientcompany: 13,
        },
        sorts: {
          lastname: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getMotifs(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getMotifs,
      {
        filters: {
          visibiliteoutil: 1
        },
        sorts: {
          libelle: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getServicesAffectes(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getServices,
      {
        sorts: {
          libelle: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getMotifsCollab(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getMotifs,
      {
        filters: {
          nature: "SUIVIQUAL"
        },
        sorts: {
          libelle: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getEntreprises(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getEntreprises,
      {
        filters: {
          active: "oui",
          type: "entreprise",
          status: "CONFIRME",
        },
        sorts: {
          name: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getAnimateurs(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getAnimateurs,
      {
        filters: {
          active: "oui",
        },
        sorts: {
          animateur: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getMandants(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getMandants,
      {
        filters: {
          active: "oui",
          "type.nature": "DO",
        },
        sorts: {
          name: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getEmetteurs(): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getEmetteurs,
      {
        active: "oui",
      },
      { headers: httpHeaders }
    );
  }

  // Outils Stats

  getDemandeTraiteStatJ(myDate): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDate,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ1(myDateJ1): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ1,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ2(myDateJ2): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ2,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ3(myDateJ3): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ3,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ4(myDateJ4): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ4,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ5(myDateJ5): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ5,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ6(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ7(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ8(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ9(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ10(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ11(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ12(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getDemandeTraiteStatJ13(myDateJ6): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getDeamndeTraiteStat,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: "TERMINE",
          modifiedat: myDateJ6,
        },
      },
      { headers: httpHeaders }
    );
  }

  getOpportunity(form): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getAllOpportunity,
      {
        filters: {
          id: form,
        },
      },
      { headers: httpHeaders }
    );
  }

  getNSinistre(form): Observable<any> {
    return this.httpClient.post<any[]>(
      ApiConfig.getAllOpportunity,
      {
        filters: {
          nsinistrecompagnie: form,
        },
      },
      { headers: httpHeaders }
    );
  }

  // Update Fiche Qualité

  updateFiche(fiche): Observable<any[]> {
    const data = {
      statut: fiche.statutcode,
      urgence: fiche.urgence,
      fichierdatefichecancal: fiche.fichierdatefichecancal,
      delairetourinterne: fiche.delairetourinterne,
      datederetourattendu2: fiche.relanceinterne,
    };
    const dataJson = JSON.stringify(data);

    const formData = new FormData();
    formData.append("data", dataJson);

    return this.httpClient.post<any[]>(
      `${ApiConfig.uploadFiles}/${fiche.id}`,
      formData,
      { headers: httpHeaders }
    );
  }

  updateAffectation(fiche): Observable<any[]> {
    const id = this.FicheId;
    const updateFicheAffect = {
      affectea: fiche.affectea,
    };
    return this.httpClient.put<any[]>(
      `${ApiConfig.updateFiche}/${id}`,
      updateFicheAffect,
      { headers: httpHeaders }
    );
  }

  updatePoleDynaren(fiche): Observable<any[]> {
    const id = this.FicheId;
    const updateFichePole = {
      suiviservice: fiche.suiviservice,
    };
    return this.httpClient.put<any[]>(
      `${ApiConfig.updateFiche}/${id}`,
      updateFichePole,
      { headers: httpHeaders }
    );
  }

  updateSuiviPar(fiche): Observable<any[]> {
    const id = this.FicheId;
    const updateFicheSuivi = {
      suivipar: fiche.suivipar,
    };
    return this.httpClient.put<any[]>(
      `${ApiConfig.updateFiche}/${id}`,
      updateFicheSuivi,
      { headers: httpHeaders }
    );
  }

  postNewEchange(fiche): Observable<any[]> {
    const id = this.FicheId;
    console.log(fiche);
    console.log(id);
    const postEchange = {
      message: fiche.contenu,
    };
    return this.httpClient.post<any[]>(
      `${ApiConfig.postNewEchange}/${id}`,
      postEchange,
      { headers: httpHeaders }
    );
  }

  postNewEmail(fiche): Observable<any[]> {
    const id = this.FicheId;
    console.log(fiche);
    const idDossier = fiche.dossier;
    const postEmail = {
      from: "qualite@dynaren.fr",
      to: fiche.to,
      dossier: fiche.dossier,
      objet: fiche.objet,
      message: fiche.contenu,
      type: fiche.type,
      visibilite: fiche.visibilite,
    };
    return this.httpClient.post<any[]>(
      `${ApiConfig.postNewEmail}/${idDossier}`,
      postEmail,
      { headers: httpHeaders }
    );
  }

  deleteDemande(id: number): Observable<any[]> {
    const AbandonFiche = {
      statut: "ABAND",
      dysfonctionnementAvere: 0,
    };
    return this.httpClient.put<any[]>(
      `${ApiConfig.updateFiche}/${id}`,
      AbandonFiche,
      { headers: httpHeaders }
    );
  }

  // Upload Document dans l'update de fiche qualité

  upload(file: File): Observable<any> {
    const id = this.FicheId;
    const formData = new FormData();
    formData.append("file", file);
    return this.httpClient.post<any[]>(
      `${ApiConfig.uploadFiles}/${id}`,
      formData,
      { headers: httpHeaders }
    );
  }

  // CreateFiche

  createInfo(fiche): Observable<any> {

    if (fiche.urgence) {
      if (fiche.opportunitynsinistrecompagniename) {
        this.createFiche = {
          opportunity: fiche.opportid,
          description: fiche.description,
          createdby: fiche.createdby,
          urgence: fiche.urgence,
          concernesociete: fiche.opportunitynsinistrecompagniename,
          dysfonctionnements: fiche.dysfonctionnementslibelle,
          mandant: fiche.mandant,
        };
      } else {
        this.createFiche = {
          opportunity: fiche.opportid,
          description: fiche.description,
          createdby: fiche.createdby,
          urgence: fiche.urgence,
          concernesociete: 13,
          dysfonctionnements: fiche.dysfonctionnementslibelle,
          mandant: fiche.mandant,
        };
      }
    } else {
      if (fiche.opportunitynsinistrecompagniename) {
        this.createFiche = {
          opportunity: fiche.opportid,
          description: fiche.description,
          createdby: fiche.createdby,
          urgence: "Non-urgent",
          concernesociete: fiche.opportunitynsinistrecompagniename,
          dysfonctionnements: fiche.dysfonctionnementslibelle,
          mandant: fiche.mandant,
        };
      } else {
        this.createFiche = {
          opportunity: fiche.opportid,
          description: fiche.description,
          createdby: fiche.createdby,
          urgence: "Non-urgent",
          concernesociete: 13,
          dysfonctionnements: fiche.dysfonctionnementslibelle,
          mandant: fiche.mandant,
        };
      }
    }

    const dataJson = JSON.stringify(this.createFiche);

    const formData = new FormData();
    formData.append("data", dataJson);

    return this.httpClient.post<any[]>(`${ApiConfig.postFiche}`, formData, {
      headers: httpHeaders,
    });
  }

  uploadCreate(file: File, id): Observable<any> {

    const formData = new FormData();
    formData.append("file", file);

    return this.httpClient.post<any[]>(
      `${ApiConfig.uploadFiles}/${id}`,
      formData,
      { headers: httpHeaders }
    );
  }

  createAffectation(ficheid, fiche): Observable<any> {
    const createFicheAffectation = {
      affectea: fiche.affectea,
    };
    return this.httpClient.put<any[]>(
      `${ApiConfig.updateFiche}/${ficheid}`,
      createFicheAffectation,
      { headers: httpHeaders }
    );
  }

  createSuivi(ficheid, fiche): Observable<any> {
    const createFicheSuivi = {
      suivipar: fiche.suivipar,
      concern: fiche.serviceConcerne,
    };
    return this.httpClient.put<any[]>(
      `${ApiConfig.updateFiche}/${ficheid}`,
      createFicheSuivi,
      { headers: httpHeaders }
    );
  }

  createPole(ficheid, fiche): Observable<any> {
    const createFichePole = {
      suiviservice: fiche.suiviservice,
    };
    return this.httpClient.put<any[]>(
      `${ApiConfig.updateFiche}/${ficheid}`,
      createFichePole,
      { headers: httpHeaders }
    );
  }


  // Module de recherche avancé

  getSearchAll(forms): Observable<any[]> {

    let finperiode = "";
    if (forms.finperiode) {
    const d = new Date(forms.finperiode);
    const newD = new Date(d.getTime() + 60 * 60 * 24 * 1000);
    finperiode = newD.toISOString().split('T')[0];
    }

    console.log(forms);

    return this.httpClient.post<any[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          id: forms.id,
          opportunity: forms.opportunityid,
          "dysfonctionnements IN": forms.dysfonctionnementsid,
          statut: forms.statutcode,
          concernesociete: forms.opportunitynsinistrecompagniename,
          "opportunity.opportunityanimateur": forms.animateur,
          "opportunity.clientcompany": forms.opportunityclientcompany,
          "origine.code": forms.emetteur,
          affectea: forms.affectea,
          concern: forms.serviceConcerne,
          suivipar: forms.suivipar,
          "opportunity.region": forms.region,
          "opportunity.disasterzipcode LIKE": `${forms.departement}%`,
          urgence: forms.urgence,
          "opportunity.insurantlastname": forms.insurantlastname,
          "opportunity.insurantfirstname": forms.insurantfirstname,
          "opportunity.disasterdate": forms.disasterdate,
          datedecloture: forms.cloturedate,
          "createdat >": forms.debutperiode,
          "createdat <": finperiode

        },
      },
      { headers: httpHeaders }
    );
  }

  getSearchAllMandant(forms): Observable<any[]> {
    const cieId = JSON.parse(localStorage.getItem("CurrentUser"));

    return this.httpClient.post<any[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          id: forms.id,
          opportunity: forms.opportunityid,
          "dysfonctionnements IN": forms.dysfonctionnementsid,
          statut: forms.statutcode,
          // concernesociete: forms.opportunitynsinistrecompagniename,
          // "opportunity.opportunityanimateur": forms.animateur,
          // affectea: forms.affectea,
          // concern: forms.serviceConcerne,
          // suivipar: forms.suivipar,
          // "opportunity.region": forms.region,
          // "opportunity.disasterzipcode LIKE": `${forms.departement}%`,
          // urgence: forms.urgence,
          "opportunity.insurantlastname": forms.insurantlastname,
          "opportunity.insurantfirstname": forms.insurantfirstname,
          "opportunity.disasterdate": forms.disasterdate,
          "opportunity.nsinistrecompagnie": forms.sinistreid,
          "opportunity.clientcompany": cieId.clientcompany.id,
        },
      },
      { headers: httpHeaders }
    );
  }

  getStatistiqueMandant(): Observable<any> {
    return this.httpClient.get<any[]>(
      ApiConfig.getStatistiqueMandant,
      { headers: httpHeaders }
    );
  }

  // Module de recherche general sur les TDB

  getSearchAllTDBNumber(searchTerm, status): Observable<any[]> {
    return this.httpClient.post<any[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: status,
          "opportunity.clientcompany IN": `${searchTerm}`
        },
        format: "count",
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getSearchAllTDB(searchTerm, status): Observable<any[]> {
    return this.httpClient.post<any[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: status,
          "opportunity.clientcompany IN": `${searchTerm}`
        },
        format: "flat",
        limit: 15,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  readAllPageSearch(offset, status, searchTerm): Observable<Qualification[]> {
    return this.httpClient.post<Qualification[]>(
      ApiConfig.getAllFiche,
      {
        filters: {
          nature: "SUIVIQUAL",
          "type IN": ["ANOMALIE", "RECLAMATION_CIE"],
          statut: status,
          "opportunity.clientcompany IN": `${searchTerm}`
        },
        format: "flat",
        limit: 15,
        page: offset,
        sorts: {
          createdat: "DESC",
        },
      },
      { headers: httpHeaders }
    );
  }

  getMandantsByName(searchTerm): Observable<any> {

    return this.httpClient.post<any[]>(
      ApiConfig.getMandants,
      {
        filters: {
          active: "oui",
          "type.nature": "DO",
          "name LIKE": `${searchTerm}%`
        },
        sorts: {
          name: "ASC",
        },
      },
      { headers: httpHeaders }
    );
  }


}
