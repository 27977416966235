import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../../../api.service';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-register-boxed',
  templateUrl: './register-boxed.component.html',
  styles: []
})
export class RegisterBoxedComponent implements OnInit {
  pass: string;
  angForm: FormGroup;
  constructor(private fb: FormBuilder, private dataService: ApiService, private router: Router) {
  this.angForm = this.fb.group({
  email: ['', [Validators.required, Validators.minLength(1), Validators.email]],
  password: ['', Validators.required],
  nom: ['', Validators.required],
  prenom: ['', Validators.required],
  });
  }
  ngOnInit() {
  }

  postdata(angForm1) {
  // this.dataService.userregistration(angForm1.value.nom, angForm1.value.prenom,
  // angForm1.value.email, angForm1.value.password).pipe(first())
  // .subscribe(
  // data => {
  // this.router.navigate(['login']);
  // },
  // error => {
  // });
  }
  get email() { return this.angForm.get('email'); }
  get password() { return this.angForm.get('password'); }
  get nom() { return this.angForm.get('nom'); }
  get prenom() { return this.angForm.get('prenom'); }
  // get username() { return this.angForm.get('username'); }

}
