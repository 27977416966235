// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  grantType : 'password',
  username : 'qualite.dynaren.fr',
  password : '7WUv2edus8AGNAVE',
// assetdev
  clientId : '20_3t1fvy2kqlgkgwg4c8skgow0wss4wkgwsk08ck4wco80ws8ko0',
  clientSecret : '4trtsc8mkwis8c0w08ww0wkswwggw8wg0kcg8og4sk0ok8gcos',
// assetdev2
  // clientId : '21_3wx838s2ircwkck88g0wc00c4g4k0o4okwsck8soccgc8g408c',
  // clientSecret : '41w11tk3itwko0swggcc44ckwggwk8s4osokkowgcg088o80c8',
// assetdev3
  // clientId : '22_ 26mm6ykviqo0soc8s0ooco4sccgg840c4cg00wgwk884oo048o',
  // clientSecret : '4jf0wzkvlts0kgk4kgkkkoko4sk4cg04s8c40skgo48084s84s'
// assetpreprod
  // clientId : '23_1xebkx64le9wcgso48csc84sw88scog44kowg0occgcgckgss0',
  // clientSecret : '5ovo3j9wlgso04k004sg8408os0c4kg8ks8s84ko80wk8w0ww0',
// assetmetier
  // clientId : '24_4n5cwaqp5aucg00kwosswsg8gg40owgg4g0kogkskg4osgcg00',
  // clientSecret : '2cnpzcpwib28s08g4c0k440ggg00444ggc88wggckwso8gkssg',
// asset
  // clientId : '19_2w8pj3xgnmw48wcscw0w0k8s4so888gwcgc0404skog4w8gw0k',
  // clientSecret : '3pkwq7kum5eso8wgscww00coc0o8o8ok8o0wggc8og888s8gwo',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
