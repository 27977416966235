import { Component, OnInit } from '@angular/core';
import { Color } from 'ng2-charts/ng2-charts';
import { ApiService } from '../../../api.service';
import { Qualification } from '../../../qualification';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiConfig } from "../../../ApiConfig";


@Component({
  selector: 'app-votreespace',
  templateUrl: './votreespace.component.html',
  styleUrls: ['./votreespace.component.sass']
})
export class VotreespaceComponent implements OnInit {

  heading = 'Vos en cours';
  analytics = true;
  qualite: boolean;
  autre: boolean;
  retour = true;
  currentDate = new Date();
  formattedDate = (moment(this.currentDate)).format('DD-MM-YYYY');
  subheading = `Dernière mise à jour : ${this.formattedDate}`;
  page = 1;
  pageDemandes = 1;
  pageQualifications = 1;
  pageServices = 1;
  pageDO = 1;
  pageFinalisations = 1;
  pageSize = 15;
  previousPage: any;
  previousPageDemandes: any;
  previousPageQualifications: any;
  previousPageServices: any;
  previousPageDO: any;
  previousPageFinalisations: any;
  dossierId: any ;
  selctDossier: number;
  demandes: Qualification[];
  demandesQualifications: Qualification[];
  demandesServices: Qualification[];
  demandesDO: Qualification[];
  demandesFinalisations: Qualification[];
  demandesNumber: any;
  demandesQualificationsNumber: any;
  demandesServicesNumber: any;
  demandesDONumber: any;
  demandesFinalisationsNumber: any;

  urlPhotoColab = './assets/images/avatars/';

  constructor(private apiService: ApiService, public activeRoute: ActivatedRoute, public router: Router) { }

  public options = {
    layout: {
      padding: {
        left: 0,
        right: 8,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false
        }
      }],
      xAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false
  };

  ngOnInit() {
    this.analytics = true;
    this.qualite = true;
    this.autre = false;

    this.apiService.readDemandesNumber().subscribe((demandesNumber: any) => {
      this.demandesNumber = demandesNumber;
    });
    this.apiService.readDemandes().subscribe((demandes: Qualification[]) => {
      this.demandes = demandes;
    });

    this.apiService.readDemandesQualificationNumber().subscribe((demandesQualificationsNumber: any) => {
      this.demandesQualificationsNumber = demandesQualificationsNumber;
    });
    this.apiService.readDemandesQualification().subscribe((demandesQualifications: Qualification[]) => {
      this.demandesQualifications = demandesQualifications;
    });

    this.apiService.readDemandesServiceNumber().subscribe((demandesServicesNumber: any) => {
      this.demandesServicesNumber = demandesServicesNumber;
    });
    this.apiService.readDemandesService().subscribe((demandesServices: Qualification[]) => {
      this.demandesServices = demandesServices;
    });

    this.apiService.readDemandesDONumber().subscribe((demandesDONumber: any) => {
      this.demandesDONumber = demandesDONumber;
    });
    this.apiService.readDemandesDO().subscribe((demandesDO: Qualification[]) => {
      this.demandesDO = demandesDO;
    });

    this.apiService.readDemandesFinalisationNumber().subscribe((demandesFinalisationsNumber: any) => {
      this.demandesFinalisationsNumber = demandesFinalisationsNumber;
    });
    this.apiService.readDemandesFinalisation().subscribe((demandesFinalisations: Qualification[]) => {
      this.demandesFinalisations = demandesFinalisations;
    });
  }

  getDossier(id) {
    this.apiService.getDossier(id).subscribe((qualif: Qualification[]) => {
      this.dossierId = id;
      this.apiService.DossierId = this.dossierId;
      localStorage.setItem("currentDossier", JSON.stringify(id));
      const myurl = `${"/dashboard/anomalie/"}${id}`;
      this.router.navigate([myurl]);
      this.ngOnInit();
    });
  }

  getRedirection(id) {
      window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
  }

  loadPage(page: number, tab: number) {
    console.log(tab);

    if (tab === 1) {
      if (page !== this.previousPageDemandes) {
        this.previousPageDemandes = page;
        const offset = page * 15 - 15;
        this.apiService.readDemandesPage(offset).subscribe((demandes: any) => {
          this.demandes = demandes;
        }, (error: any) => {
        console.log(error) ;
        });

      }
    }
    if (tab === 2) {
      if (page !== this.previousPageQualifications) {
        this.previousPageQualifications = page;
        const offset = page * 15 - 15;
        this.apiService.readDemandesQualificationPage(offset).subscribe((demandesQualifications: any) => {
          this.demandesQualifications = demandesQualifications;
        }, (error: any) => {
        console.log(error) ;
        });
      }

    }
    if (tab === 3) {
      if (page !== this.previousPageServices) {
        this.previousPageServices = page;
        const offset = page * 15 - 15;
        this.apiService.readDemandesServicePage(offset).subscribe((demandesServices: any) => {
          this.demandesServices = demandesServices;
        }, (error: any) => {
        console.log(error) ;
        });
      }

    }
    if (tab === 4) {
      if (page !== this.previousPageDO) {
        this.previousPageDO = page;
        const offset = page * 15 - 15;
        this.apiService.readDemandesDOPage(offset).subscribe((demandesDO: any) => {
          this.demandesDO = demandesDO;
        }, (error: any) => {
        console.log(error) ;
        });
      }

    }
    if (tab === 5) {
      if (page !== this.previousPageFinalisations) {
        this.previousPageFinalisations = page;
        const offset = page * 15 - 15;
        this.apiService.readDemandesFinalisationPage(offset).subscribe((demandesFinalisations: any) => {
          this.demandesFinalisations = demandesFinalisations;
        }, (error: any) => {
        console.log(error) ;
        });
      }

    }
  }
}
