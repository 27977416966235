import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { Subscription } from "rxjs";
import { ThemeOptions } from "../../../../../theme-options";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-user-box",
  templateUrl: "./user-box.component.html",
})
export class UserBoxComponent implements OnInit, OnDestroy {
  getProfil$: Subscription;
  profilConnected = [];
  userCredentials: any;
  urlPhotoColab = "./assets/images/avatars/";
  urlPhotoAutre = "./assets/images/avatars/Autres";
  photoAutre: boolean;

  constructor(
    public globals: ThemeOptions,
    private dataService: ApiService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.userCredentials = this.dataService.currentUserValue;
    this.getProfil$ = this.dataService
      .getActiveProfil(this.userCredentials.id)
      .subscribe(
        (data) => {
          this.profilConnected.push(data);
        },
        (error) => {
          this.photoAutre = true;
        }
      );
  }

  ngOnDestroy(): void {
    this.getProfil$.unsubscribe();
  }

  logout() {
    this.dataService.deleteToken();
    this.router.navigateByUrl("/pages/login-boxed");
    // this.cookieService.delete("autoLoginCookies", '/');
    this.cookieService.deleteAll('/', '.dynaren.fr');
  }
}
