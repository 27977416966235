import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchbarService {

  private ficheNumberSource = new BehaviorSubject('');
  currentficheNumber = this.ficheNumberSource.asObservable();

  private ficheSource = new BehaviorSubject('');
  currentfiche = this.ficheSource.asObservable();

  private searchTermSource = new BehaviorSubject('');
  currentsearchTermSource = this.searchTermSource.asObservable();

  private searchTermValue = new BehaviorSubject('');
  currentsearchTermValue = this.searchTermValue.asObservable();

  private paginationOffset = new BehaviorSubject('');
  currentpaginationOffset = this.paginationOffset.asObservable();


  constructor() { }



  changeFichesNumber(fichesNumber: any) {
    this.ficheNumberSource.next(fichesNumber);
  }

  changeFiches(fiches: any) {
    this.ficheSource.next(fiches);
  }

  changesearchTermExist(searchTermExist: any) {
    this.searchTermSource.next(searchTermExist);
  }

  changesearchTermValue(searchTermValue: any) {
    this.searchTermValue.next(searchTermValue);
  }

  changePaginationOffset(paginationOffset: any) {
    console.log(paginationOffset);
    this.paginationOffset.next(paginationOffset);
  }

}
