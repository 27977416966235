import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Color } from 'ng2-charts/ng2-charts';
import { ApiService } from '../../../api.service';
import { Qualification } from '../../../qualification';
import { ApiConfig } from "../../../ApiConfig";
import { SearchbarService } from '../../Components/searchbar/searchbarservice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-retourservice',
  templateUrl: './retourservice.component.html',
  styleUrls: ['./retourservice.component.sass']
})
export class RetourserviceComponent implements OnInit, OnDestroy  {

  heading ;
  mandant;
  analytics = false;
  retour = true;
  subheading = 'Dernière mise à jour : 25/01/2021 ';
  page: any = 1;
  pageSize = 15;
  previousPage: any;
  services: any;
  servicesNumber: any;
  urlPhotoColab = './assets/images/avatars/';
  userCredentials: any;
  dossierId: any ;

  @Input() ficheNumberDefault;
  statutTbd = "ATT_SERVICE";
  ficheNumber: any;
  subscription: Subscription;
  fiche: any;
  subscription2: Subscription;
  searchTermExist: any = false;
  subscription3: Subscription;
  searchTermValue: any;
  oldsearchTermValue: any;
  subscription4: Subscription;
  paginationOffsetChange: any;
  subscription5: Subscription;

  constructor(private apiService: ApiService, public activeRoute: ActivatedRoute, public router: Router, private data: SearchbarService) {}

  ngOnInit() {
    this.analytics = true;

    this.userCredentials = this.apiService.currentUserValue;
    if (this.userCredentials.profil === "Collaborateur") {

      this.mandant = false;
      this.heading = 'Demandes en attentes retour des services';


      this.apiService.readAllServicesNumber().subscribe((servicesNumber: any) => {
        this.servicesNumber = servicesNumber;
      });

      this.apiService. readAllServices().subscribe((services: Qualification[]) => {
        this.services = services;
      });
    } else {

      this.mandant = true;
      this.heading = 'Réclamations en attente retour des services';

      this.apiService.readAllServicesMandantNumber(this.userCredentials.clientcompany.id).subscribe((servicesNumber: any) => {
        this.servicesNumber = servicesNumber;
      });
      this.apiService. readAllServicesMandant(this.userCredentials.clientcompany.id).subscribe((services: Qualification[]) => {
        this.services = services;
      });
    }

    this.subscription = this.data.currentficheNumber .subscribe(ficheNumber => this.servicesNumber = ficheNumber);
    this.subscription2 = this.data.currentfiche.subscribe(fiche => this.services = fiche);
    this.subscription3 = this.data.currentsearchTermSource.subscribe(searchTermExist => this.searchTermExist = searchTermExist);
    this.subscription4 = this.data.currentsearchTermValue.subscribe(searchTermValue => this.searchTermValue  = searchTermValue );
    this.subscription5 = this.data.currentpaginationOffset.subscribe(paginationOffsetChange => this.page  = paginationOffsetChange);
  }

  getDossier(id) {
    this.apiService.getDossier(id).subscribe((qualif: Qualification[]) => {
      this.dossierId = id;
      this.apiService.DossierId = this.dossierId;
      localStorage.setItem("currentDossier", JSON.stringify(id));
      const myurl = `${"/dashboard/anomalie/"}${id}`;
      this.router.navigate([myurl]);
      this.ngOnInit();
    });
  }

  getRedirection(id) {
    if ( this.mandant) {
      window.open(`${ApiConfig.redirectionExtranet}/${id}`, '_blank');
    } else {
      window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
    }
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.loadData(this.previousPage);
    }
  }

  loadData(page: number) {
    const offset = page * 15 - 15;
    console.log(offset);
    if (this.userCredentials.profil === "Collaborateur") {

      if (!this.searchTermExist) {
        this.apiService.readAllServicesPage(offset).subscribe((services: any) => {
          this.services = services;
        }, (error: any) => {
        console.log(error) ;
        });
      } else {
        this.apiService.readAllPageSearch(offset, this.statutTbd, this.searchTermValue).subscribe((ficheSearch: any) => {
          this.services = ficheSearch;
        }, (error: any) => {
        console.log(error) ;
        });

      }
    } else {
      this.apiService.readAllServicesMandantPage(offset, this.userCredentials.clientcompany.id).subscribe((services: any) => {
        this.services = services;
      }, (error: any) => {
      console.log(error) ;
      });

    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscription4.unsubscribe();
    this.subscription5.unsubscribe();
  }

}
