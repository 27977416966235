import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { AnalyticsComponent } from './View/Dashboards/analytics/analytics.component';
import { ForgotPasswordBoxedComponent } from './View/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { LoginBoxedComponent } from './View/UserPages/login-boxed/login-boxed.component';
import { RegisterBoxedComponent } from './View/UserPages/register-boxed/register-boxed.component';
import { StandardComponent } from './View/Elements/Buttons/standard/standard.component';
import { DropdownsComponent } from './View/Elements/dropdowns/dropdowns.component';
import { CardsComponent } from './View/Elements/cards/cards.component';
import { ListGroupsComponent } from './View/Elements/list-groups/list-groups.component';
import { TimelineComponent } from './View/Elements/timeline/timeline.component';
import { IconsComponent } from './View/Elements/icons/icons.component';
import { AccordionsComponent } from './View/Components/accordions/accordions.component';
import { TabsComponent } from './View/Components/tabs/tabs.component';
import { CarouselComponent } from './View/Components/carousel/carousel.component';
import { ModalsComponent } from './View/Components/modals/modals.component';
import { ProgressBarComponent } from './View/Components/progress-bar/progress-bar.component';
import { PaginationComponent } from './View/Components/pagination/pagination.component';
import { TooltipsPopoversComponent } from './View/Components/tooltips-popovers/tooltips-popovers.component';
import { TablesMainComponent } from './View/Tables/tables-main/tables-main.component';
import { ChartBoxes3Component } from './View/Widgets/chart-boxes3/chart-boxes3.component';
import { ControlsComponent } from './View/Forms/Elements/controls/controls.component';
import { LayoutComponent } from './View/Forms/Elements/layout/layout.component';
import { ChartjsComponent } from './View/Charts/chartjs/chartjs.component';
import { QualificationComponent } from './View/Dashboards/qualification/qualification.component';
import { RetourserviceComponent } from './View/Dashboards/retourservice/retourservice.component';
import { RetourdoComponent } from './View/Dashboards/retourdo/retourdo.component';
import { FinalisationComponent } from './View/Dashboards/finalisation/finalisation.component';
import { RechercheComponent } from './View/Dashboards/recherche/recherche.component';
import { DemandeComponent } from './View/Dashboards/demande/demande.component';
import { DemandequaliteComponent } from './View/Dashboards/demandequalite/demandequalite.component';
import { VotreespaceComponent } from './View/Dashboards/votreespace/votreespace.component';
import { ImportComponent } from './View/Dashboards/import/import.component';
import { ClotureComponent } from './View/Dashboards/cloture/cloture.component';
import { AuthguardGuard } from './authguard.guard';
import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { VoletsinappsComponent } from './View/Dashboards/voletsinapps/voletsinapps.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: "pages/login-boxed",
    pathMatch: 'full'
  },
  // {path: '', component: LoginBoxedComponent},
  {
    path: 'dashboard',
    component: BaseLayoutComponent,
    children: [
      // {
      //   path: '', component: AnalyticsComponent, data: { extraParameter: 'dashboardsMenu' }
      // },

      {path: 'anomalie/:id',  component: ControlsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard]},

      { path: 'home', component: AnalyticsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard]},
      // { path: 'forms/controls', component: ControlsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      // { path: 'anomalie', component: ControlsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'forms/qualification', component: QualificationComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'forms/retourservice', component: RetourserviceComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard]},
      { path: 'forms/retourdo', component: RetourdoComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'forms/finalisation', component: FinalisationComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard]},
      { path: 'forms/recherche', component: RechercheComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard]},
      { path: 'forms/demande', component: DemandeComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      // { path: 'forms/demandequalite', component: DemandequaliteComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'nouvelle/demande', component: DemandequaliteComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'nouvelle/demande/ref/:id', component: DemandequaliteComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'forms/votreespace', component: VotreespaceComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'forms/import', component: ImportComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'forms/cloture', component: ClotureComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },
      { path: 'forms/voletsinapps', component: VoletsinappsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [AuthguardGuard] },


      // { path: 'elements/buttons-standard', component: StandardComponent, data: { extraParameter: 'elementsMenu' } },
      // { path: 'elements/dropdowns', component: DropdownsComponent, data: { extraParameter: 'elementsMenu' } },
      // { path: 'elements/icons', component: IconsComponent, data: { extraParameter: 'elementsMenu' } },
      // { path: 'elements/cards', component: CardsComponent, data: { extraParameter: 'elementsMenu' } },
      // { path: 'elements/list-group', component: ListGroupsComponent, data: { extraParameter: 'elementsMenu' } },
      // { path: 'elements/timeline', component: TimelineComponent, data: { extraParameter: 'elementsMenu' } },
      // { path: 'components/tabs', component: TabsComponent, data: { extraParameter: 'componentsMenu' } },
      // { path: 'components/accordions', component: AccordionsComponent, data: { extraParameter: 'componentsMenu' } },
      // { path: 'components/modals', component: ModalsComponent, data: { extraParameter: 'componentsMenu' } },
      // { path: 'components/progress-bar', component: ProgressBarComponent, data: { extraParameter: 'componentsMenu' } },
      // { path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: { extraParameter: 'componentsMenu' } },
      // { path: 'components/carousel', component: CarouselComponent, data: { extraParameter: 'componentsMenu' } },
      // { path: 'components/pagination', component: PaginationComponent, data: { extraParameter: 'componentsMenu' } },
      // { path: 'tables/bootstrap', component: TablesMainComponent, data: { extraParameter: 'tablesMenu' } },
      // { path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: { extraParameter: 'pagesMenu3' } },
      // { path: 'forms/layouts', component: LayoutComponent, data: { extraParameter: 'formElementsMenu' } },
      // { path: 'charts/chartjs', component: ChartjsComponent, data: { extraParameter: '' } },
    ]
  },
  {
    path: 'pages/login-boxed',
    component: LoginBoxedComponent,
  },
  {
    path: 'pages/register',
    component: RegisterBoxedComponent,
  },
  { path: '**', component: LoginBoxedComponent, }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      // useHash: true,
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
