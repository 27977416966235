import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { forEach } from '@angular/router/src/utils/collection';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiConfig } from "../../../ApiConfig";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.sass']
})
export class RechercheComponent implements OnInit {
constructor(private apiService: ApiService, private spinner: NgxSpinnerService,
            private formBuilder: FormBuilder, public activeRoute: ActivatedRoute, public router: Router, public datePipe: DatePipe) {}

  heading = 'Recherche avancée';
  analytics = false;
  retour = true;
  currentDate = new Date();
  formattedDate = (moment(this.currentDate)).format('DD-MM-YYYY');
  subheading = `Dernière mise à jour : ${this.formattedDate}`;
  activeId = 'RechercheMenu';
  resultatRecherche = false;
  fileName = 'ExportExcel.xlsx';
  searchForm: FormGroup;
  optionsMotifs: any;
  optionsMotifsCollab: any;
  optionsEntreprises: any;
  optionsAnimateurs: any;
  optionsMandants: any;
  optionsEmetteurs: any;
  collabsQualite: any;
  collabsDynaren: any;
  serviceDynaren: any;
  results: any;
  userCredentials: any;
  mandant: boolean;
  noResults = false;
  obj: any;

  ngOnInit() {
    this.analytics = true;

    this.userCredentials = this.apiService.currentUserValue;
    if ( this.userCredentials.profil === "Collaborateur" ) {
      this.mandant = false;
    } else {
      this.mandant = true;
    }

    this.searchForm = this.formBuilder.group({
      id: [""],
      opportunityid: [""],
      sinistreid: [""],
      dysfonctionnementsid: [""],
      statutcode: [""],
      opportunitynsinistrecompagniename: [""],
      animateur: [""],
      opportunityclientcompany: [""],
      emetteur: [""],
      affectea: [""],
      serviceConcerne: [""],
      suivipar: [""],
      region: [""],
      departement: [""],
      urgence: [""],
      insurantlastname: [""],
      insurantfirstname: [""],
      disasterdate: [""],
      cloturedate: [""],
      debutperiode: [""],
      finperiode: [""],
    });

     // GEt Form suivipar, affectea, motifsreclamation, entreprise

    // this.collabsQualite = this.apiService.collabsQualite;
    this.apiService.getCollabQualite().subscribe((collabsQualite: any[]) => {
      this.collabsQualite = collabsQualite;
    });

    // this.optionsMotifs = this.apiService.optionsMotifs;
    this.apiService.getMotifs().subscribe((motifs: any[]) => {
      this.optionsMotifs = motifs;
    });

    // this.optionsMotifsCollab = this.apiService.optionsMotifsCollab;
    this.apiService.getMotifsCollab().subscribe((motifsCollab: any[]) => {
      this.optionsMotifsCollab  = motifsCollab;
    });

    // this.optionsEntreprises = this.apiService.optionsEntreprises;
    this.apiService.getEntreprises().subscribe((entreprises: any[]) => {
      this.optionsEntreprises = entreprises;
    });

    // this.collabsDynaren = this.apiService.collabsDynaren;
    this.apiService.getCollabDynaren().subscribe((collabsDynaren: any[]) => {
      this.collabsDynaren  = collabsDynaren;
    });

    // this.serviceDynaren = this.apiService.serviceDynaren;
    this.apiService.getServiceDynaren().subscribe((serviceDynaren: any[]) => {
      this.serviceDynaren = serviceDynaren;
    });

    // this.optionsAnimateurs = this.apiService.optionsAnimateurs;
    this.apiService.getAnimateurs().subscribe((animateurs: any[]) => {
      this.optionsAnimateurs = animateurs;
    });

    // this.optionsEmetteurs = this.apiService.optionsEmetteurs;
    this.apiService.getEmetteurs().subscribe((emetteurs: any[]) => {
      this.optionsEmetteurs = emetteurs;
    });


    // this.optionsMandants = this.apiService.optionsMandants;
    this.apiService.getMandants().subscribe((mandants: any[]) => {
      this.optionsMandants = mandants;
    });
  }

  search() {
    this.resultatRecherche = true;
    this.noResults = false;
    this.spinner.show();
    if (this.mandant === false) {
      this.apiService.getSearchAll(this.searchForm.value).subscribe((qualif: any[]) => {
        this.results = qualif;
        this.spinner.hide();
        if ( qualif.length <= 0 ) {
          console.log('Aucune correspondance avec vos critéres de recherche');
          this.spinner.hide();
          this.noResults = true;
        }
        this.ngOnInit();
      });
    } else {
      this.apiService.getSearchAllMandant(this.searchForm.value).subscribe((qualif: any[]) => {
        this.results = qualif;
        this.spinner.hide();
        if ( qualif.length <= 0 ) {
          console.log('Aucune correspondance avec vos critéres de recherche');
          this.spinner.hide();
          this.noResults = true;
        }
        this.ngOnInit();
      });
    }


  }

  getDossier(id) {
    this.apiService.getDossier(id).subscribe((qualif: any[]) => {
      this.apiService.DossierId = id;
      localStorage.setItem("currentDossier", JSON.stringify(id));
      const myurl = `${"/dashboard/anomalie/"}${id}`;
      this.router.navigate([myurl]);
      this.ngOnInit();
    });
  }

  getRedirection(id) {
    if ( this.mandant) {
      window.open(`${ApiConfig.redirectionExtranet}/${id}`, '_blank');
    } else {
      window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
    }
  }


  exportexcel(): void {

    if ( this.userCredentials.profil === "Collaborateur" ) {
      // construction données
    const tmp = [];
    // tslint:disable-next-line:forin
    for (const i in this.results) {

        let affecteaLastname = '';
        let affecteaFisrtname = '';
        let serviceConcerne = '';
        let entrepriseConcerne = '';
        let delaisTraitement = 'En cours' ;
        let datePremierRetour = "Aucun";
        let description = '';
        let departement = '';
        let clientcompanyName = '';
        let animateur = '';
        let insurantlastname = '';
        let insurantfirstname = '';
        const motifArray = [];

        if (this.results[i].affectea) {
          affecteaLastname = this.results[i].affectea.lastname;
          affecteaFisrtname = this.results[i].affectea.firstname;
        }

        if (this.results[i].suiviservice) {
          serviceConcerne = this.results[i].suiviservice.libelle;
        }

        if (this.results[i].description) {
          description = this.results[i].description;
        }

        if (this.results[i].opportunity.clientcompany) {
          clientcompanyName = this.results[i].opportunity.clientcompany.name;
        }

        if (this.results[i].opportunity.departement) {
          departement = this.results[i].opportunity.departement;
        }

        if (this.results[i].opportunity.opportunityanimateur) {
          animateur = this.results[i].opportunity.opportunityanimateur.animateur;
        }

        if (this.results[i].opportunity.insurantfirstname) {
          insurantfirstname = this.results[i].opportunity.insurantfirstname;
        }

        if (this.results[i].opportunity.insurantfirstname) {
          insurantlastname = this.results[i].opportunity.insurantlastname;
        }

        if (this.results[i].concernesociete) {
          entrepriseConcerne = this.results[i].concernesociete.name;
        }

        if (this.results[i].dysfonctionnements) {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < this.results[i].dysfonctionnements.length; j++) {
            motifArray.push(this.results[i].dysfonctionnements[j].libelle);
          }
        }

        if (this.results[i].dateretour !== false) {
          const transform  = this.results[i].dateretour;
          datePremierRetour = this.datePipe.transform(transform, 'dd/MM/yyyy');
        }

        if (this.results[i].datedecloture) {
          const date1: any = new Date(this.results[i].datedecloture);
          const date2: any = new Date(this.results[i].createdat);
          // tslint:disable-next-line:no-shadowed-variable
          let tmp = date2 - date1;
          tmp = Math.floor(tmp / 1000);
          const sec = tmp % 60;
          tmp = Math.floor((tmp - sec) / 60);
          const min = tmp % 60;
          tmp = Math.floor((tmp - min) / 60);
          const hour = tmp % 24;
          tmp = Math.floor((tmp - hour) / 24);
          const day = tmp;
          delaisTraitement = Math.abs(day) + ' jours ' + Math.abs(hour) + ' heures ' + Math.abs(min) + ' minutes';
        }

        this.obj = {
          Fiche: this.results[i].id,
          Dossier: this.results[i].opportunity.code,
          Sinistre: this.results[i].opportunity.nsinistrecompagnie,
          Date_reception: (moment(this.results[i].createdat)).format('DD-MM-YYYY'),
          Charge_traitement: affecteaFisrtname + ' ' + affecteaLastname ,
          Service_concerne: serviceConcerne ,
          Nombre_relance: this.results[i].compteurrelances ,
          Motifs: JSON.stringify(motifArray) ,
          Expediteur: this.results[i].createdby ,
          Commentaire: description,
          Assure: insurantfirstname + ' ' + insurantlastname ,
          Departement: departement,
          Compagnie: clientcompanyName,
          Animateur: animateur,
          Entreprise_concerne: entrepriseConcerne ,
          Date_premier_retour: datePremierRetour,
          Delais_traitement: delaisTraitement
        };
        tmp.push(this.obj);
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      tmp,
      {
      // tslint:disable-next-line:max-line-length
      header: ['Fiche', 'Dossier', 'Sinistre', 'Date_reception', 'Charge_traitement',
      'Service_concerne', 'Nombre_relance', 'Motifs', 'Expediteur', 'Commentaire',
      'Assure', 'Departement', 'Compagnie', 'Animateur', 'Entreprise_concerne', 'Date_premier_retour', 'Delais_traitement'],
      }
    );
    // creation fichier
    XLSX.utils.book_append_sheet(wb, ws, 'Export');
    XLSX.writeFile(wb, this.fileName);
    }
  }
}
