import { Component, OnInit } from "@angular/core";
import { Color } from "ng2-charts/ng2-charts";
import { ApiService } from "../../../api.service";
import { Qualification } from "../../../qualification";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from 'xlsx';
import * as moment from "moment";
import { CookieService } from 'ngx-cookie-service';
import { ApiConfig } from "../../../ApiConfig";



@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
})
export class AnalyticsComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    public activeRoute: ActivatedRoute,
    public router: Router,
    private cookieService: CookieService,
  ) {
    apiService.getLoggedInName.subscribe((name) => this.changeName(name));
    if (this.apiService.isLoggedIn()) {
      this.loginbtn = false;
      this.logoutbtn = true;
    } else {
      this.loginbtn = true;
      this.logoutbtn = false;
    }
  }

  public qualificationsNumber: any;
  public servicesNumber: any;
  public doNumber: any;
  public finalisationsNumber: any;
  public type: any;
  public cookieValue: any;
  public userCredentials: any;
  public mandant: boolean;
  public show = "show";
  public loginbtn: boolean;
  public logoutbtn: boolean;
  public qualifications: Qualification[];
  public finalisations: Qualification[];
  public services: Qualification[];
  public dos: Qualification[];
  public termines: Qualification[];
  public totals: Qualification[];
  public totals2: any;
  public dossierId: any;
  public selctDossier: number;
  public urlPhotoColab = "./assets/images/avatars/";
  public heading = "Support Qualité";
  public analytics = true;
  public qualite: boolean;
  public autre: boolean;
  public myDate = new Date();
  public date1 = new Date(Date.now() - 3600 * 1000 * 24);
  public date2 = new Date(Date.now() - 3600 * 1000 * 48);
  public date3 = new Date(Date.now() - 3600 * 1000 * 72);
  public date4 = new Date(Date.now() - 3600 * 1000 * 96);
  public date5 = new Date(Date.now() - 3600 * 1000 * 120);
  public date6 = new Date(Date.now() - 3600 * 1000 * 144);
  public formattedDate = moment(this.myDate).format("YYYY-MM-DD");
  public formattedDate1 = moment(this.date1).format("YYYY-MM-DD");
  public formattedDate2 = moment(this.date2).format("YYYY-MM-DD");
  public formattedDate3 = moment(this.date3).format("YYYY-MM-DD");
  public formattedDate4 = moment(this.date4).format("YYYY-MM-DD");
  public formattedDate5 = moment(this.date5).format("YYYY-MM-DD");
  public formattedDate6 = moment(this.date6).format("YYYY-MM-DD");
  public date7 = new Date(Date.now() - 3600 * 1000 * 169);
  public date8 = new Date(Date.now() - 3600 * 1000 * 193);
  public date9 = new Date(Date.now() - 3600 * 1000 * 217);
  public date10 = new Date(Date.now() - 3600 * 1000 * 241);
  public date11 = new Date(Date.now() - 3600 * 1000 * 265);
  public date12 = new Date(Date.now() - 3600 * 1000 * 289);
  public date13 = new Date(Date.now() - 3600 * 1000 * 313);
  public formattedDate7 = moment(this.date7).format("YYYY-MM-DD");
  public formattedDate8 = moment(this.date8).format("YYYY-MM-DD");
  public formattedDate9 = moment(this.date9).format("YYYY-MM-DD");
  public formattedDate10 = moment(this.date10).format("YYYY-MM-DD");
  public formattedDate11 = moment(this.date11).format("YYYY-MM-DD");
  public formattedDate12 = moment(this.date12).format("YYYY-MM-DD");
  public formattedDate13 = moment(this.date13).format("YYYY-MM-DD");
  public subheading = `Dernière mise à jour : ${this.formattedDate}  `;
  public nbrDossiers: number;
  public nbrDossiersTraites: number;
  public pourcentageDossiersTraites: number;
  public myDateNumber: any;
  public myDateNumber1: any;
  public myDateNumber2: any;
  public myDateNumber3: any;
  public myDateNumber4: any;
  public myDateNumber5: any;
  public myDateNumber6: any;
  public myDateNumber7: any;
  public myDateNumber8: any;
  public myDateNumber9: any;
  public myDateNumber10: any;
  public myDateNumber11: any;
  public myDateNumber12: any;
  public myDateNumber13: any;
  public optionsEntreprises;
  public mandantsTab = [];
  public statsMandantArray = [];
  fileName = 'Export_Taux_Reclamations_compagnie.xlsx';
  public obj: any;
  public datasum = [];
  public labels = [
    this.formattedDate6,
    this.formattedDate5,
    this.formattedDate4,
    this.formattedDate3,
    this.formattedDate2,
    this.formattedDate1,
    this.formattedDate,
  ];
  public suiviTotal;
  public suiviTotallastweek;
  public pourcentage;
  public datasets = [
    {
      label: "Nbr de fiche",
      data: this.datasum,
      datalabels: {
        display: false,
      },
    },
  ];
  public lineChartColors: Color[] = [
    {
      backgroundColor: "rgba(247, 185, 36, 0.2)",
      borderColor: "#f7b924",
      borderCapStyle: "round",
      borderDash: [],
      borderWidth: 4,
      borderDashOffset: 0.0,
      borderJoinStyle: "round",
      pointBorderColor: "#f7b924",
      pointBackgroundColor: "#fff",
      pointHoverBorderWidth: 4,
      pointRadius: 6,
      pointBorderWidth: 5,
      pointHoverRadius: 8,
      pointHitRadius: 10,
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#f7b924",
    },
  ];
  public options = {
    layout: {
      padding: {
        left: 0,
        right: 8,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: true,
            fontColor: "#BEBEBE",
            // fontSize: 12
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  ngOnInit() {
    this.analytics = true;
    this.qualite = true;
    this.autre = false;
    this.userCredentials = this.apiService.currentUserValue;
    if (this.userCredentials.profil === "Collaborateur") {
      this.mandant = false;
    } else {
      this.mandant = true;
    }
    // Recuparation des listes de formulaire Entreprises, Collaborateur Qualité, Motifs réclamation, Animateur, Mandants...
    // this.apiService.getEntreprises().subscribe((entreprises: any[]) => {
    //   this.apiService.optionsEntreprises = entreprises;
    // });

    // this.apiService.getCollabQualite().subscribe((collabsQualite: any[]) => {
    //   this.apiService.collabsQualite = collabsQualite;
    // });

    // this.apiService.getMotifs().subscribe((motifs: any[]) => {
    //   this.apiService.optionsMotifs = motifs;
    // });

    // this.apiService.getMotifsCollab().subscribe((motifsCollab: any[]) => {
    //   this.apiService.optionsMotifsCollab = motifsCollab;
    // });

    // this.apiService.getCollabDynaren().subscribe((collabsDynaren: any[]) => {
    //   this.apiService.collabsDynaren = collabsDynaren;
    // });

    // this.apiService.getServiceDynaren().subscribe((serviceDynaren: any[]) => {
    //   this.apiService.serviceDynaren = serviceDynaren;
    // });

    // this.apiService.getAnimateurs().subscribe((animateurs: any[]) => {
    //   this.apiService.optionsAnimateurs = animateurs;
    // });

    // this.apiService.getMandants().subscribe((mandants: any[]) => {
    //   // tslint:disable-next-line:prefer-for-of
    //   for (let i = 0; i < mandants.length; i++) {
    //     if (
    //       mandants[i].type.type === "Compagnie d'assurance" ||
    //       mandants[i].type.type === "Intermédiaire d'assurance" ||
    //       mandants[i].type.type === "Cabinet d'experts"
    //     ) {
    //       this.mandantsTab.push(mandants[i]);
    //     }
    //   }
    //   if (JSON.parse(localStorage.getItem("listMandants"))) {
    //   } else {
    //     localStorage.setItem("listMandants", JSON.stringify(this.mandantsTab));
    //   }
    // });

    // this.apiService.getEmetteurs().subscribe((emetteurs: any[]) => {
    //   this.apiService.optionsEmetteurs = emetteurs;
    // });

    // Graphique suivi de dossier

    this.apiService
      .getDemandeTraiteStatJ(this.formattedDate)
      .subscribe((myDateNumber: any) => {
        if (myDateNumber.length === "undefined") {
          this.myDateNumber = 0;
        } else {
          this.myDateNumber = myDateNumber.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ1(this.formattedDate1)
      .subscribe((myDateNumber1: any) => {
        if (myDateNumber1.length === "undefined") {
          this.myDateNumber1 = 0;
        } else {
          this.myDateNumber1 = myDateNumber1.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ2(this.formattedDate2)
      .subscribe((myDateNumber2: any) => {
        if (myDateNumber2.length === "undefined") {
          this.myDateNumber2 = 0;
        } else {
          this.myDateNumber2 = myDateNumber2.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ3(this.formattedDate3)
      .subscribe((myDateNumber3: any) => {
        if (myDateNumber3.length === "undefined") {
          this.myDateNumber3 = 0;
        } else {
          this.myDateNumber3 = myDateNumber3.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ4(this.formattedDate4)
      .subscribe((myDateNumber4: any) => {
        if (myDateNumber4.length === "undefined") {
          this.myDateNumber4 = 0;
        } else {
          this.myDateNumber4 = myDateNumber4.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ5(this.formattedDate5)
      .subscribe((myDateNumber5: any) => {
        if (myDateNumber5.length === "undefined") {
          this.myDateNumber5 = 0;
        } else {
          this.myDateNumber5 = myDateNumber5.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ6(this.formattedDate6)
      .subscribe((myDateNumber6: any) => {
        if (myDateNumber6.length === "undefined") {
          this.myDateNumber6 = 0;
        } else {
          this.myDateNumber6 = myDateNumber6.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ7(this.formattedDate7)
      .subscribe((myDateNumber7: any) => {
        if (myDateNumber7.length === "undefined") {
          this.myDateNumber7 = 0;
        } else {
          this.myDateNumber7 = myDateNumber7.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ8(this.formattedDate8)
      .subscribe((myDateNumber8: any) => {
        if (myDateNumber8.length === "undefined") {
          this.myDateNumber8 = 0;
        } else {
          this.myDateNumber8 = myDateNumber8.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ9(this.formattedDate9)
      .subscribe((myDateNumber9: any) => {
        if (myDateNumber9.length === "undefined") {
          this.myDateNumber9 = 0;
        } else {
          this.myDateNumber9 = myDateNumber9.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ10(this.formattedDate10)
      .subscribe((myDateNumber10: any) => {
        if (myDateNumber10.length === "undefined") {
          this.myDateNumber10 = 0;
        } else {
          this.myDateNumber10 = myDateNumber10.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ11(this.formattedDate11)
      .subscribe((myDateNumber11: any) => {
        if (myDateNumber11.length === "undefined") {
          this.myDateNumber11 = 0;
        } else {
          this.myDateNumber11 = myDateNumber11.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ12(this.formattedDate12)
      .subscribe((myDateNumber12: any) => {
        if (myDateNumber12.length === "undefined") {
          this.myDateNumber12 = 0;
        } else {
          this.myDateNumber12 = myDateNumber12.length;
        }
      });

    this.apiService
      .getDemandeTraiteStatJ13(this.formattedDate13)
      .subscribe((myDateNumber13: any) => {
        if (myDateNumber13.length === "undefined") {
          this.myDateNumber13 = 0;
        } else {
          this.myDateNumber13 = myDateNumber13.length;
        }
      });

    setTimeout(() => {
      this.getChartData();
    }, 5000);

    if (this.userCredentials.profil === "Collaborateur") {
      this.apiService
        .readQualifications()
        .subscribe((qualifications: Qualification[]) => {
          this.qualifications = qualifications;
        });
      this.apiService.readAllQualificationsNumber().subscribe((qualificationsNumber: any) => {
          this.qualificationsNumber = qualificationsNumber;
      });

      this.apiService.readServices().subscribe((services: Qualification[]) => {
        this.services = services;
      });
      this.apiService.readAllServicesNumber().subscribe((servicesNumber: any) => {
        this.servicesNumber = servicesNumber;
      });
      this.apiService
        .readFinalisation()
        .subscribe((finalisations: Qualification[]) => {
          this.finalisations = finalisations;
        });
      this.apiService.readAllFinalisationNumber().subscribe((finalisationsNumber: any) => {
          this.finalisationsNumber = finalisationsNumber;
      });
      this.apiService.readDo().subscribe((dos: Qualification[]) => {
        this.dos = dos;
      });
      this.apiService.readAllDoNumber().subscribe((doNumber: any) => {
        this.doNumber = doNumber;
      });
      this.apiService.readAllNumber().subscribe((totals: any) => {
            this.totals2 = totals;
            // this.pourcentageDossiersTraites = Math.round(
            //   (this.nbrDossiersTraites / this.nbrDossiers) * 100
            // );
      });
      this.apiService
        .readAllTerminesAnalytics()
        .subscribe((termines: Qualification[]) => {
          this.termines = termines;
          this.nbrDossiersTraites = termines.length;
          console.log(this.nbrDossiersTraites);
          this.pourcentageDossiersTraites = Math.round(
                (this.nbrDossiersTraites / this.totals2.count) * 100
          );

          // this.apiService.readAll().subscribe((totals: Qualification[]) => {
          //   this.totals = totals;
          //   this.nbrDossiers = totals.length;
          //   this.pourcentageDossiersTraites = Math.round(
          //     (this.nbrDossiersTraites / this.nbrDossiers) * 100
          //   );
          // });

        });

      this.apiService.getStatistiqueMandant().subscribe((statsMandant: any[]) => {
        this.statsMandantArray = statsMandant;
      });

    } else {
      this.apiService
        .readQualificationsMandant(this.userCredentials.clientcompany.id)
        .subscribe((qualifications: Qualification[]) => {
          this.qualifications = qualifications;
        });
      this.apiService
        .readServicesMandant(this.userCredentials.clientcompany.id)
        .subscribe((services: Qualification[]) => {
          this.services = services;
        });
      this.apiService
        .readFinalisationMandant(this.userCredentials.clientcompany.id)
        .subscribe((finalisations: Qualification[]) => {
          this.finalisations = finalisations;
        });
      this.apiService
        .readDoMandant(this.userCredentials.clientcompany.id)
        .subscribe((dos: Qualification[]) => {
          this.dos = dos;
        });
      this.apiService
        .readAllTerminesMandant(this.userCredentials.clientcompany.id)
        .subscribe((termines: Qualification[]) => {
          this.termines = termines;
          this.nbrDossiersTraites = termines.length;
          this.apiService
            .readAllMandant(this.userCredentials.clientcompany.id)
            .subscribe((totals: Qualification[]) => {
              this.totals = totals;
              this.nbrDossiers = totals.length;
              this.pourcentageDossiersTraites = Math.round(
                (this.nbrDossiersTraites / this.nbrDossiers) * 100
              );
            });
        });
    }
  }

  getChartData() {
    this.datasets = [
      {
        label: "Nbr de fiche",
        // tslint:disable-next-line:max-line-length
        data: [
          this.myDateNumber6,
          this.myDateNumber5,
          this.myDateNumber4,
          this.myDateNumber3,
          this.myDateNumber2,
          this.myDateNumber1,
          this.myDateNumber,
        ],
        datalabels: {
          display: false,
        },
      },
    ];

    // tslint:disable-next-line:max-line-length
    const dataday = [
      this.myDateNumber6,
      this.myDateNumber5,
      this.myDateNumber4,
      this.myDateNumber3,
      this.myDateNumber2,
      this.myDateNumber1,
      this.myDateNumber,
    ];
    // tslint:disable-next-line:max-line-length
    const datasumdaylastweek = [
      this.myDateNumber13,
      this.myDateNumber12,
      this.myDateNumber11,
      this.myDateNumber10,
      this.myDateNumber9,
      this.myDateNumber8,
      this.myDateNumber7,
    ];

    this.suiviTotal = dataday.reduce((sum, current) => sum + current, 0);
    this.suiviTotallastweek = datasumdaylastweek.reduce(
      (sum, current) => sum + current,
      0
    );
    this.pourcentage = Math.round(
      ((this.suiviTotal - this.suiviTotallastweek) / this.suiviTotallastweek) *
      100
    );
  }

  getDossier(id) {
    this.apiService.getDossier(id).subscribe((qualif: Qualification[]) => {
      localStorage.setItem("currentDossier", JSON.stringify(id));
      const myurl = `${"/dashboard/anomalie/"}${id}`;
      this.router.navigate([myurl]);
      this.ngOnInit();
    });
  }

  getRedirection(id) {
    if ( this.mandant) {
      console.log(id);
      window.open(`${ApiConfig.redirectionExtranet}/${id}`, '_blank');
    } else {
      console.log(id);
      window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
    }
  }

  changeName(name: boolean): void {
    this.logoutbtn = name;
    this.loginbtn = !name;
  }

  logout() {
    this.apiService.deleteToken();
    window.location.href = window.location.href;
  }

  exportexcel(): void {
    const tmp = [];
    // tslint:disable-next-line:forin
    for (const i in this.statsMandantArray) {

        const nombre = (this.statsMandantArray[i].annee.nb_fichequalites * 100) / this.statsMandantArray[i].annee.nb_dossiers;
        const roundedString = Number(nombre.toFixed(2));

        this.obj = {
          Mandant: this.statsMandantArray[i].mandant,
          Nombre_dossiers: this.statsMandantArray[i].annee.nb_dossiers,
          Nombre_fiches_qualite: this.statsMandantArray[i].annee.nb_fichequalites,
          Janvier: this.statsMandantArray[i].mois["01"].nb_fichequalites,
          Fevrier: this.statsMandantArray[i].mois["02"].nb_fichequalites,
          Mars: this.statsMandantArray[i].mois["03"].nb_fichequalites,
          Avril: this.statsMandantArray[i].mois["04"].nb_fichequalites,
          Mai: this.statsMandantArray[i].mois["05"].nb_fichequalites,
          Juin: this.statsMandantArray[i].mois["06"].nb_fichequalites,
          Juillet: this.statsMandantArray[i].mois["07"].nb_fichequalites,
          Aout: this.statsMandantArray[i].mois["08"].nb_fichequalites,
          Septembre: this.statsMandantArray[i].mois["09"].nb_fichequalites,
          Octobre: this.statsMandantArray[i].mois["10"].nb_fichequalites,
          Novembre: this.statsMandantArray[i].mois["11"].nb_fichequalites,
          Decembre: this.statsMandantArray[i].mois["12"].nb_fichequalites,
          Taux_reclamations: roundedString
        };
        tmp.push(this.obj);
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      tmp,
      {
        header: ['Mandant', 'Nombre_dossiers', 'Nombre_fiches_qualite',
        'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'
        , 'Taux_reclamations'],
      }
    );
    XLSX.utils.book_append_sheet(wb, ws, 'Export');
    XLSX.writeFile(wb, this.fileName);


  }
}
