import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Color } from 'ng2-charts/ng2-charts';
import { Router, ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { ApiService } from '../../../api.service';
import { NewDossier } from 'src/app/newDossier';
import * as moment from 'moment';


interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [{
  type: 'success',
  message: 'Votre demande à bien été créée',
}
];

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})


export class ImportComponent implements OnInit {
  heading = 'Importer votre fichier Excel';
  analytics = false;
  retour = true;
  currentDate = new Date();
  formattedDate = (moment(this.currentDate)).format('DD-MM-YYYY');
  subheading = `Dernière mise à jour : ${this.formattedDate}`;
  pictureFiles = false;
  pictureDrop = true;
  willDownload = false;
  dataTab: [] = [];
  url = './assets/images/avatars/'
  sendImport = false;
  etapeTroisValide: boolean;
  alerts: Alert[];

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService,) { this.reset(); }


  @ViewChild('fileDropRef') fileDropEl: ElementRef;
  files: any[] = [];

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  reset() {
    this.alerts = Array.from(ALERTS);
  }

  etapeTrois() {
    this.etapeTroisValide = true;
    setTimeout(() => {
      // this.router.navigate(['/dashboard/home']);
    }, 2000);
    // this.apiService.createNewDossier(this.dataTab).subscribe((newDossier: NewDossier) => {
    // });
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString2 = jsonData;
      const dataTab1 = dataString2.Sheet1;
      const dataString = JSON.stringify(dataTab1);
      this.slugify(dataString);
      this.setDownload(dataString);
    };
    reader.readAsBinaryString(file);
  }

  onFileChange2(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString2 = jsonData;
      const dataTab1 = dataString2.Sheet1;
      const dataString = JSON.stringify(dataTab1);
      this.slugify(dataString);
      this.setDownload(dataString);
    };
    reader.readAsBinaryString(file);
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
    this.onFileChange2($event);
    this.sendImport = true;
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
    this.sendImport = true;
  }

  slugify(dataString) {
    const map = {
      'N': '#',
      '_': ' ',
      'a': 'á|à|ã|â|À|Á|Ã|Â',
      'e': 'é|è|ê|É|È|Ê',
      'i': 'í|ì|î|Í|Ì|Î',
      'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
      'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      'c': 'ç|Ç',
      'n': 'ñ|Ñ'
    };

    // tslint:disable-next-line:forin
    for (const pattern in map) {
      dataString = dataString.replace(new RegExp(map[pattern], 'g'), pattern);
    }

    this.dataTab = JSON.parse(dataString);
    return dataString;
  }


  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
      el.setAttribute("download", 'xlsxtojson.json');
    }, 1000);
  }



  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
    this.dataTab = [];
    this.sendImport = false;
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
    // this.sendImport = true;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  ngOnInit() {
  }
}
