import { Component, ComponentFactoryResolver, OnInit, ViewEncapsulation } from "@angular/core";
import { ApiService } from "../../../../api.service";
import { Qualification } from "../../../../qualification";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NewEchange } from "src/app/newEchange";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ChangeDetectorRef } from '@angular/core';
import { ApiConfig } from "../../../../ApiConfig";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-controls",
  templateUrl: "./controls.component.html",
  styles: []
})
export class ControlsComponent implements OnInit {
  // Upload
  selectedFiles: FileList;
  progressInfos = [];
  message = "";
  fileInfos: Observable<any>;
  //
  covea: boolean;
  qualifications: any;
  qualifications2: any;
  currentDate = new Date();
  EchangeForm: FormGroup;
  EmailForm: FormGroup;

  updateForm: FormGroup;
  updateAffect: FormGroup;
  updateSuivi: FormGroup;
  updatePole: FormGroup;
  heading: any;
  subheading: any;
  icon = "pe-7s-display1 icon-gradient bg-premium-dark";
  selectCollaborateur = false;
  selectCollaborateurQualite = false;
  dossierId: any;
  NumeroDossier: number;
  closeResult: string;
  profilConnected = [];
  userCredentials: any;
  getProfil$: Subscription;
  collabsQualite: any;
  collabsDynaren: any;
  affectok: boolean;
  suiviok: boolean;
  suiviServiceok: boolean;
  concernok: boolean;
  mandant: boolean;
  dysfonctionnementsexits: boolean;
  clotureok: boolean;
  ServicesAffectes: any;
  nofiche = false;
  delaisTraitement = 'En cours' ;
  myDate = new Date();
  formattedDate = moment(this.myDate).format("YYYY-MM-DD");

  private routeSub: Subscription;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private toaster: ToastrService
  ) {}

  ngOnInit() {

    this.spinner.show();
    this.updateForm = this.formBuilder.group({
      opportunityid: [""],
      opportunitynsinistrecompagnie: [""],
      urgence: [""],
      statutcode: [""],
      id: [""],
      concernesocietename: [""],
      createdat: [""],
      delairetourinterne: [""],
      fichierdatefichecancal: [""],
      relanceinterne: [""],
      opportunitycreatedat: [""],
      opportunityopportunityanimateuranimateur: [""],
      opportunityclientcompanyname: [""],
      opportunityregion: [""],
      opportunitydepartement: [""],
      opportunitydisasterzipcode: [""],
      file: [""],
      modifiedat: [this.formattedDate],
    });

    this.updateAffect = this.formBuilder.group({
      affecte: [""],
      affectea: [""],
    });

    this.updatePole = this.formBuilder.group({
      suiviservice: [""],
    });

    this.updateSuivi = this.formBuilder.group({
      serviceConcerne: [""],
      suivipar: [""],
    });

    // Methode de chargement initialement developpé Single page Application / url dynamique
    // this.dossierId = this.apiService.DossierId;

    this.routeSub = this.activeRoute.params.subscribe((params) => {
      this.dossierId = params["id"];
    });

    this.userCredentials = this.apiService.currentUserValue;

    if (this.userCredentials.clientcompany) {
      if (this.userCredentials.clientcompany.companyname.startsWith("COVEA")) {
        this.covea = true;
      } else {
        this.covea = false;
      }
    } else {
      this.covea = false;
    }

    if (this.userCredentials.profil === "Collaborateur") {
      this.mandant = false;
    } else {
      this.mandant = true;
    }

    this.getProfil$ = this.apiService
      .getActiveProfil(this.userCredentials.id)
      .subscribe((data) => {
        this.profilConnected.push(data);
      });

    if (this.dossierId) {
      this.apiService
        .getDossier(this.dossierId)
        .subscribe((qualifications: any) => {
          this.qualifications = qualifications;


          // En attente de l'ajout du champs email dans la reponse API
          this.qualifications['email'] = [
            {
                "auteur": "SWISSLIFE ROUBAIX",
                "datecreation": "2022-12-07T10:39:23Z",
                "contenu": "\n Fake Test email",
                "type":"mandant",
                "visibilite":"externe",
                "sujet": "SUIVIQUAL",
                "raw": "\nTest email"
            },
            {
              "auteur": "SWISSLIFE ROUBAIX",
              "datecreation": "2022-12-07T10:39:23Z",
              "contenu": "\nFake Test email2",
              "type":"mandant",
              "visibilite":"externe",
              "sujet": "SUIVIQUAL",
              "raw": "\nTest email2"
          }
        ];

          console.log(this.qualifications);

          this.cdRef.detectChanges();
          this.nofiche = false;
          this.spinner.hide();

          if (qualifications.statut.code === "EN_FIN") {
            this.clotureok = true;
          } else {
            this.clotureok = false;
          }

          if (this.qualifications.dysfonctionnements.length >= 0) {
            this.dysfonctionnementsexits = true;
          }
          this.apiService.FicheId = this.dossierId;

          if (this.qualifications.affectea) {
            this.affectok = true;
          } else {
            this.affectok = false;
          }

          if (this.qualifications.suivipar) {
            this.suiviok = true;
          } else {
            this.suiviok = false;
          }

          if (this.qualifications.datedecloture) {
            const date1: any = new Date(this.qualifications.datedecloture);
            const date2: any = new Date(this.qualifications.createdat);
            // tslint:disable-next-line:no-shadowed-variable
            let tmp = date2 - date1;
            tmp = Math.floor(tmp / 1000);
            const sec = tmp % 60;
            tmp = Math.floor((tmp - sec) / 60);
            const min = tmp % 60;
            tmp = Math.floor((tmp - min) / 60);
            const hour = tmp % 24;
            tmp = Math.floor((tmp - hour) / 24);
            const day = tmp;
            this.delaisTraitement = Math.abs(day) + ' jours ' + Math.abs(hour) + ' heures ' + Math.abs(min) + ' minutes';
          }

          if (this.qualifications.suiviService) {
            this.suiviServiceok = true;
          } else {
            this.suiviServiceok = false;
          }
          if (this.qualifications.concern) {
            this.concernok = true;
          } else {
            this.concernok = false;
          }
          this.NumeroDossier = this.qualifications.opportunity.id;
          this.qualifications2 = this.qualifications;
          this.heading = `Suivi et avancement dossier n°${this.NumeroDossier}`;
          this.subheading = `Vous trouverez sur cette page les informations relatives au dossier n°${this.NumeroDossier}`;
        },
        (error: any) => {
          this.nofiche = true;
          this.spinner.hide();
        }
        );
    } else {
      // Recuparation du dossier courant au rafraichissemnt de la page
      this.dossierId = localStorage.getItem("currentDossier");
      this.apiService
        .getDossier(this.dossierId)
        .subscribe((qualifications: any) => {
          this.qualifications = qualifications;
          
          this.cdRef.detectChanges();
          this.spinner.hide();

          if (qualifications.statut.code === "EN_FIN") {
            this.clotureok = true;
          } else {
            this.clotureok = false;
          }

          if (this.qualifications.dysfonctionnements.length >= 0) {
            this.dysfonctionnementsexits = true;
          }
          this.apiService.FicheId = this.dossierId;
          if (this.qualifications.affectea.id) {
            this.affectok = true;
          } else {
            this.affectok = false;
          }
          if (this.qualifications.suivipar) {
            this.suiviok = true;
          } else {
            this.suiviok = false;
          }
          if (this.qualifications.suiviService) {
            this.suiviServiceok = true;
          } else {
            this.suiviServiceok = false;
          }
          if (this.qualifications.concern) {
            this.concernok = true;
          } else {
            this.concernok = false;
          }
          this.NumeroDossier = this.qualifications.opportunity.id;
          this.qualifications2 = this.qualifications;
          this.heading = `Suivi et avancement dossier n°${this.NumeroDossier}`;
          this.subheading = `Vous trouverez sur cette page les informations relatives au dossier n°${this.NumeroDossier}`;
        });
    }

    this.apiService.getCollabQualite().subscribe((collabsQualite: any[]) => {
      this.collabsQualite = collabsQualite;
    });

    this.apiService.getCollabDynaren().subscribe((collabsDynaren: any[]) => {
      this.collabsDynaren = collabsDynaren;
    });

    this.EchangeForm = this.formBuilder.group({
      auteur: [
        this.userCredentials.firstname + " " + this.userCredentials.lastname,
      ],
      datecreation: [""],
      contenu: [""],
    });

    this.EmailForm = this.formBuilder.group({
      dossier: [""],
      auteur: [ this.userCredentials.firstname + " " + this.userCredentials.lastname, ],
      datecreation: [""],
      destinataire: ["En attente de  l'APi"],
      visibilite: [""],
      objet: [""],
      contenu: [""],
    });

    this.apiService.getServicesAffectes().subscribe((ServicesAffectes: any[]) => {
      this.ServicesAffectes = ServicesAffectes;
    });
  }

  SelectCollaborateurQualite() {
    if (this.selectCollaborateurQualite === false) {
      this.selectCollaborateurQualite = true;
      console.log("collaborateur existant");
    } else {
      this.selectCollaborateurQualite = false;
      console.log("pas de collaborateur");
    }
  }

  SelectCollaborateur() {
    if (this.selectCollaborateur === false) {
      this.selectCollaborateur = true;
    } else {
      this.selectCollaborateur = false;
    }
  }

  // Upload Files

  selectFiles(event) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  uploadFiles() {
    this.message = "";
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }

  upload(idx, file) {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    this.apiService.upload(file).subscribe(
      (event) => {
        if (event) {
          this.progressInfos[idx].value = 100;
          // this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        }
      },
      (err) => {
        this.progressInfos[idx].value = 0;
        this.message = "Could not upload the file:" + file.name;
      }
    );
  }

  // Update Fiche Qualité

  updateFiche(id) {
    this.apiService.updateFiche(this.updateForm.value).subscribe((result) => {
      const myurl = `${"/dashboard/home"}`;
      this.router.navigate([myurl]);
    });
  }

  updateAffectation(id) {
    this.apiService.FicheId = id;
    console.log(this.updateAffect.value);
    this.apiService
      .updateAffectation(this.updateAffect.value)
      .subscribe((result) => {
        this.apiService
          .getDossier(this.dossierId)
          .subscribe((qualifications: any) => {
            this.qualifications = qualifications;
            this.ngOnInit();
          });
      });
  }

  updatePoleDynaren(id) {
    this.apiService.FicheId = id;
    this.apiService
      .updatePoleDynaren(this.updatePole.value)
      .subscribe((result) => {
        this.apiService
          .getDossier(this.dossierId)
          .subscribe((qualifications: any) => {
            this.qualifications = qualifications;
            this.ngOnInit();
          });
      });
  }

  updateSuiviPar(id) {
    this.apiService.FicheId = id;
    this.apiService
      .updateSuiviPar(this.updateSuivi.value)
      .subscribe((result) => {
        this.apiService
          .getDossier(this.dossierId)
          .subscribe((qualifications: any) => {
            this.qualifications = qualifications;
            this.ngOnInit();
          });
      });
  }

  // Creation Echange

  postEchange(id) {
    this.apiService.FicheId = id;
    this.apiService
      .postNewEchange(this.EchangeForm.value)
      .subscribe((result) => {
        this.apiService
          .getDossier(this.dossierId)
          .subscribe((qualifications: any) => {
            this.qualifications = qualifications;
            console.log('ok');
            this.toaster.success(
              'Votre message a bien été envoyé '
            );
            this.ngOnInit();
          },
          (error) => {
            this.toaster.error("Votre message n'a pas été remis");
            console.log(error);
          });
      });
  }

  // Creation Email

  postEmail(id) {
    this.apiService.FicheId = id;
    this.apiService
      .postNewEmail(this.EmailForm.value)
      .subscribe((result) => {
        this.apiService
          .getDossier(this.dossierId)
          .subscribe((qualifications: any) => {
            console.log(qualifications);
            this.qualifications = qualifications;
            this.toaster.success(
              'Votre email a bien été envoyé '
            );
            this.ngOnInit();
          },
          (error) => {
            this.toaster.error("Votre email n'a pas été remis");
            console.log(error);
          });
      });
  }


  // Abandon Fiche Qualité

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getRedirection(id) {
    if ( this.mandant) {
      console.log(id);
      window.open(`${ApiConfig.redirectionExtranet}/${id}`, '_blank');
    } else {
      console.log(id);
      window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
    }
  }

  deleteDemande(id) {
    this.apiService.deleteDemande(id).subscribe((result) => {
      const myurl = `${"/dashboard/home"}`;
      this.router.navigate([myurl]);
    });
  }
}
