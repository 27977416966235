import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiConfig } from "../../../ApiConfig";
import { FormBuilder, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-voletsinapps',
  templateUrl: './voletsinapps.component.html',
  styleUrls: ['./voletsinapps.component.sass']
})
export class VoletsinappsComponent implements OnInit {

  heading = 'Insatisfactions Sinapps';
  currentDate = new Date();
  retour = true;
  formattedDate = (moment(this.currentDate)).format('DD-MM-YYYY');
  subheading = `Dernière mise à jour : ${this.formattedDate}`;

  page = 1;
  pageSize = 15;
  previousPage: any;

  insatisfactionsNumber: any;
  insatisfactions: any;
  insatisfactionsAll: any;
  insatisfactionsNumberTraitee: any;
  insatisfactionsNumberNonTraitee: any;
  roundedStringtraitee: any;
  roundedStringNonTraitee: any;

  searchForm: FormGroup;

  fileName = 'Export_Insatisfactions.xlsx';
  public obj: any;

  constructor(private apiService: ApiService, public activeRoute: ActivatedRoute, public router: Router,
              private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.spinner.show();

    this.searchForm = this.formBuilder.group({
      statutcode: [null],
      debutperiode: [null],
      finperiode: [null],
    });

    // Total insatisfaction
    this.apiService.readInsatisfactionsNumber(this.searchForm.value).subscribe((insatisfactionsNumber: any) => {
      this.insatisfactionsNumber = insatisfactionsNumber.compteur;
      if ( this.insatisfactionsNumber) {

    // Traitees
    this.apiService.readInsatisfactionsTraiteeNumber().subscribe((insatisfactionsNumberTraitee: any) => {
      this.insatisfactionsNumberTraitee = insatisfactionsNumberTraitee.compteur;
      const nombretraitee = ( this.insatisfactionsNumberTraitee * 100) / this.insatisfactionsNumber;
      this.roundedStringtraitee = Number(nombretraitee.toFixed(2));
    });

    // Non traitees
    this.apiService.readInsatisfactionsNonTraiteeNumber().subscribe((insatisfactionsNumberNonTraitee: any) => {
      this.insatisfactionsNumberNonTraitee = insatisfactionsNumberNonTraitee.compteur;
      const nombreNonTraitee = ( this.insatisfactionsNumberNonTraitee * 100) / this.insatisfactionsNumber;
      this.roundedStringNonTraitee = Number(nombreNonTraitee.toFixed(2));
    });

      }
    });


    this.apiService.readInsatisfactions(this.searchForm.value).subscribe((insatisfactions: any) => {
      this.insatisfactions = insatisfactions.statistiques;
    });

    this.apiService.readAllInsatisfactions().subscribe((insatisfactions: any) => {
      this.insatisfactionsAll = insatisfactions.statistiques;
      this.spinner.hide();
    });




  }

  getRedirection(id) {
    window.open(`${ApiConfig.redirectionAsset}/${id}`, '_blank');
  }

  loadPage(page: number) {

    if (page !== this.previousPage) {
      this.previousPage = page;
      const offset = page;
      this.apiService.readInsatisfactionsPage(offset, this.searchForm.value).subscribe((insatisfactions: any) => {
        this.insatisfactions = insatisfactions.statistiques;
      }, (error: any) => {
      console.log(error) ;
      });

    }
  }

  search() {

      this.spinner.show();

      this.apiService.readInsatisfactionsNumber(this.searchForm.value).subscribe((insatisfactionsNumber: any) => {
        this.insatisfactionsNumber = insatisfactionsNumber.compteur;
      });

      this.apiService.readInsatisfactions(this.searchForm.value).subscribe((insatisfactions: any) => {
        this.insatisfactions = insatisfactions.statistiques;
        this.spinner.hide();
      });
  }

  exportexcel(): void {
    const tmp = [];
    // tslint:disable-next-line:forin
    for (const i in this.insatisfactionsAll) {

        let dateTraitement = "";
        let delai = "";

        if (this.insatisfactionsAll[i].date_traitement) {
          dateTraitement =  this.insatisfactionsAll[i].date_traitement;
        }

        if (this.insatisfactionsAll[i].delai) {
          delai =  this.insatisfactionsAll[i].delai;
        }

        this.obj = {
          Dossier: this.insatisfactionsAll[i].dossier,
          Mandant: this.insatisfactionsAll[i].mandant,
          Assure: this.insatisfactionsAll[i].assure,
          Date_reception: this.insatisfactionsAll[i].date_reception,
          Traitement: this.insatisfactionsAll[i].traitement,
          Date_traitement: dateTraitement,
          Delai: delai
        };
        tmp.push(this.obj);
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      tmp,
      {
        header: ['Dossier', 'Mandant', 'Assure', 'Date_reception', 'Traitement', 'Date_traitement', 'Delai',
        `${this.insatisfactionsNumberTraitee} Insatisfactions_traitees ${this.roundedStringtraitee} % `,
        ` ${this.insatisfactionsNumberNonTraitee} Insatisfactions_non_traitees ${this.roundedStringNonTraitee} %`],
      }
    );
    XLSX.utils.book_append_sheet(wb, ws, 'Export');
    XLSX.writeFile(wb, this.fileName);


  }


}
