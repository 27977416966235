export const ApiConfig = {

// assetdev
    connexion: 'https://rest.assetdev.dynaren.fr/oauth/v2/token',
    authentification: 'https://rest.assetdev.dynaren.fr/qualite/authentification',
    getDossierById: 'https://rest.assetdev.dynaren.fr/fichequalite/read',
    // getAllFiche: 'https://rest.assetdev.dynaren.fr/fichequalite/search',
    getAllFiche: 'https://rest.assetdev.dynaren.fr/qualite/rechercher',
    getAllOpportunity: 'https://rest.assetdev.dynaren.fr/opportunities/search',
    getUserById: 'https://rest.assetdev.dynaren.fr/users/read',
    getCollabQualite: 'https://rest.assetdev.dynaren.fr/users/search',
    getCollabDynaren: 'https://rest.assetdev.dynaren.fr/contacts/search',
    getServiceDynaren: 'https://rest.assetdev.dynaren.fr/qualiteconcerne/search',
    getMotifs: 'https://rest.assetdev.dynaren.fr/qualitedysfonctionnement/search',
    getEntreprises: 'https://rest.assetdev.dynaren.fr/clientcompanies/search',
    getAnimateurs: 'https://rest.assetdev.dynaren.fr/contactassociealanimateur/search',
    getMandants: 'https://rest.assetdev.dynaren.fr/clientcompanies/search',
    getEmetteurs: 'https://rest.assetdev.dynaren.fr/qualiteorigine/search',
    // getDeamndeTraiteStat: 'https://rest.assetdev.dynaren.fr/fichequalite/search',
    getDeamndeTraiteStat: 'https://rest.assetdev.dynaren.fr/qualite/rechercher',
    updateFiche: 'https://rest.assetdev.dynaren.fr/fichequalite/update',
    uploadFiles: 'https://rest.assetdev.dynaren.fr/qualite/modifier',
    postNewEchange: 'https://rest.assetdev.dynaren.fr/qualite/ajouter-message',
    postNewEmail: '',
    postFiche: 'https://rest.assetdev.dynaren.fr/qualite/creer',
    getServices: 'https://rest.assetdev.dynaren.fr/FqServicesaffectes/search',
    redirectionAsset: 'https://assetdev.dynaren.fr/dossiers/detail',
    redirectionExtranet: 'https://extranetdev.dynaren.fr/dossier/detail/id',
    getStatistiqueMandant: 'https://rest.assetdev.dynaren.fr/qualite/statistiques-mandants',
    getInsatisfactions: 'https://rest.assetdev.dynaren.fr/qualite/anomalies-sinapps'


// asset
    // connexion: 'https://rest.asset.dynaren.fr/oauth/v2/token',
    // authentification: 'https://rest.asset.dynaren.fr/qualite/authentification',
    // getDossierById: 'https://rest.asset.dynaren.fr/fichequalite/read',
    // getAllFiche: 'https://rest.asset.dynaren.fr/fichequalite/search',
    // getAllFiche: 'https://rest.asset.dynaren.fr/qualite/rechercher',
    // getAllOpportunity: 'https://rest.asset.dynaren.fr/opportunities/search',
    // getUserById: 'https://rest.asset.dynaren.fr/users/read',
    // getCollabQualite: 'https://rest.asset.dynaren.fr/users/search',
    // getCollabDynaren: 'https://rest.asset.dynaren.fr/contacts/search',
    // getServiceDynaren: 'https://rest.asset.dynaren.fr/qualiteconcerne/search',
    // getMotifs: 'https://rest.asset.dynaren.fr/qualitedysfonctionnement/search',
    // getEntreprises: 'https://rest.asset.dynaren.fr/clientcompanies/search',
    // getAnimateurs: 'https://rest.asset.dynaren.fr/contactassociealanimateur/search',
    // getMandants: 'https://rest.asset.dynaren.fr/clientcompanies/search',
    // getEmetteurs: 'https://rest.asset.dynaren.fr/qualiteorigine/search',
    // getDeamndeTraiteStat: 'https://rest.asset.dynaren.fr/fichequalite/search',
    // getDeamndeTraiteStat: 'https://rest.asset.dynaren.fr/fichequalite/search',
    // updateFiche: 'https://rest.asset.dynaren.fr/fichequalite/update',
    // uploadFiles: 'https://rest.asset.dynaren.fr/qualite/modifier',
    // postNewEchange: 'https://rest.asset.dynaren.fr/qualite/ajouter-message',
    // postNewEmail: '',
    // postFiche: 'https://rest.asset.dynaren.fr/qualite/creer',
    // getServices: 'https://rest.asset.dynaren.fr/FqServicesaffectes/search',
    // redirectionAsset: 'https://asset.dynaren.fr/dossiers/detail'
    // redirectionExtranet: 'https://extranet.dynaren.fr/dossier/detail/id',
    // getStatistiqueMandant: 'https://rest.asset.dynaren.fr/qualite/statistiques-mandants',
    // getInsatisfactions: 'https://rest.asset.dynaren.fr/qualite/anomalies-sinapps'
};
