import { Component, Input, OnInit, } from '@angular/core';
import { ApiService } from '../../../api.service';
import { SearchbarService } from './searchbarservice.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.sass']
})
export class SearchbarComponent implements OnInit{

  @Input() fichesnumberdefault: any;
  @Input() statutTbd: any;

  ficheNumber: any;
  fiche: any;
  searchTermExist: any = false;
  searchTermValue: any;

  constructor(private apiService: ApiService, private data: SearchbarService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }


  search(event: Event) {

    this.data.changePaginationOffset("0");

    const searchTerm = (event.target as HTMLInputElement).value;
    this.spinner.show();

    if (searchTerm.length > 0) {
      this.searchTermExist = true;
      this.data.changesearchTermExist(this.searchTermExist);

      this.apiService.getMandantsByName(searchTerm).subscribe((mandantsByName: any[]) => {
          const newArrayGroupe = [];
          for ( let i = 0; i < mandantsByName.length; i++) {
            newArrayGroupe.push(mandantsByName[i].id);
          }

          this.searchTermValue = newArrayGroupe;
          this.data.changesearchTermValue(this.searchTermValue);
          console.log(this.searchTermValue);

          setTimeout(() => {
            this.apiService.getSearchAllTDBNumber(this.searchTermValue, this.statutTbd).subscribe((ficheNumberSearch: any[]) => {
              this.ficheNumber = ficheNumberSearch;
              console.log(this.ficheNumber);
              this.data.changeFichesNumber(this.ficheNumber);
            }, (error: any) => {
              console.log(error) ;
            });
            this.apiService.getSearchAllTDB(this.searchTermValue, this.statutTbd).subscribe((ficheSearch: any[]) => {
              this.fiche = ficheSearch;
              this.data.changeFiches(this.fiche);
              this.spinner.hide();
            }, (error: any) => {
              console.log(error) ;
            });

          }, 3000);

      });

    } else {
      console.log(this.fichesnumberdefault);
      this.data.changeFichesNumber(this.fichesnumberdefault);

      this.apiService.getSearchAllTDB("", this.statutTbd).subscribe((ficheSearch: any[]) => {
        this.fiche = ficheSearch;
        this.data.changeFiches(this.fiche);
        this.spinner.hide();
      });

      this.searchTermExist = false;
      this.data.changesearchTermExist(this.searchTermExist);
    }
}


}
