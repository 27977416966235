import { Component, Input, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ApiService } from "../../../../../api.service";
import { ApiConfig } from "../../../../../ApiConfig";

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() type: any;

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    // plugins: {
    //   datalabels: {
    //     formatter: (value, ctx) => {
    //       const label = ctx.chart.data.labels[ctx.dataIndex];
    //       return label;
    //     },
    //   },
    // }
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'doughnut';
  public pieChartLegend = true;
  // public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [ '#d8da00',
      'DarkCyan',
      'DarkGoldenRod', 'Olive', 'DarkBlue',
      'DarkKhaki' , 'DarkOrange', 'DarkRed', 'DarkSalmon', 'DarkSlateBlue', 'DarkSlateGray',
      '#671e75', '#182c4d', 'LightPink', 'LightSteelBlue', 'Linen'
     ],
     hoverBackgroundColor: [ '#d8da00',
     'DarkCyan',
     'DarkGoldenRod', 'Olive', 'DarkBlue',
     'DarkKhaki' , 'DarkOrange', 'DarkRed', 'DarkSalmon', 'DarkSlateBlue', 'DarkSlateGray',
     '#671e75', '#182c4d', 'LightPink', 'LightSteelBlue', 'Linen'
    ]
    },
  ];

  constructor(private apiService: ApiService) { }

  ngOnInit() {

  this.apiService.getStatistiqueMandant().subscribe((statsMandant: any[]) => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < statsMandant.length; i++) {
        this.pieChartLabels.push(statsMandant[i].mandant);
        if ( this.type === 'Taux') {
          this.pieChartType = 'pie';
          const nombre = (statsMandant[i].annee.nb_fichequalites * 100) / statsMandant[i].annee.nb_dossiers;
          const roundedString = nombre.toFixed(2);
          this.pieChartData.push( Number(roundedString));
        } else {
          this.pieChartData.push(statsMandant[i].annee.nb_fichequalites);
        }
      }
  });
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {

  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {

  }

  // action en commentaire

  // changeLabels() {
  //   const words = ['hen', 'variable', 'embryo', 'instal', 'pleasant', 'physical', 'bomber', 'army', 'add', 'film',
  //     'conductor', 'comfortable', 'flourish', 'establish', 'circumstance', 'chimney', 'crack', 'hall', 'energy',
  //     'treat', 'window', 'shareholder', 'division', 'disk', 'temptation', 'chord', 'left', 'hospital', 'beef',
  //     'patrol', 'satisfied', 'academy', 'acceptance', 'ivory', 'aquarium', 'building', 'store', 'replace', 'language',
  //     'redeem', 'honest', 'intention', 'silk', 'opera', 'sleep', 'innocent', 'ignore', 'suite', 'applaud', 'funny'];
  //   const randomWord = () => words[Math.trunc(Math.random() * words.length)];
  //   this.pieChartLabels = Array.apply(null, { length: 3 }).map(_ => randomWord());
  // }

  // addSlice() {
  //   this.pieChartLabels.push(['Line 1', 'Line 2', 'Line 3']);
  //   this.pieChartData.push(400);
  //   this.pieChartColors[0].backgroundColor.push('rgba(196,79,244,0.3)');
  // }

  // removeSlice() {
  //   this.pieChartLabels.pop();
  //   this.pieChartData.pop();
  //   this.pieChartColors[0].backgroundColor.pop();
  // }

  // changeLegendPosition() {
  //   this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
  // }
}
