import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../api.service";
import * as bcrypt from "bcryptjs";
import { ApiConfig } from "../../../ApiConfig";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-login-boxed",
  templateUrl: "./login-boxed.component.html",
  styles: [],
})
export class LoginBoxedComponent implements OnInit {
  cookieValue: any;
  angForm: FormGroup;
  accessToken: any;
  myurl: any;
  model: any = {};
  loading = false;
  returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.angForm = this.fb.group({
      // email: ['', [Validators.required, Validators.minLength(1), Validators.email]],
      login: ["", [Validators.required, Validators.minLength(1)]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    this.dataService.connexionApi().subscribe(
      (newToken) => {
        this.accessToken = newToken.access_token;
      },
      (err) => {
        console.log(err);
      }
    );

    if (localStorage.getItem("Session")) {
      if (this.returnUrl !== "/") {
        this.myurl = `${this.returnUrl}`;
      } else {
        this.myurl = `${"/dashboard/home"}`;
      }
      this.router.navigate([this.myurl]);
    }


    // gestion de l'autologin avec les cookies
    this.cookieValue = this.cookieService.get("autoLoginCookies");

    setTimeout(() => {
      if (this.cookieValue) {
        const AlreadyConnect = JSON.parse(this.cookieValue);
        this.postAlreadyLog(AlreadyConnect);
      } else {
        console.log("Aucun cookie");
      }
    }, 1000);
  }

  postAlreadyLog(AlreadyConnect) {
    this.dataService
      .userAlreadylogin(AlreadyConnect)
      .pipe()
      .subscribe(
        (data) => {
          if (this.returnUrl !== "/") {
            this.myurl = `${this.returnUrl}`;
          } else {
            this.myurl = `${"/dashboard/home"}`;
          }
          this.router.navigate([this.myurl]);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  postdata(angForm1) {
    this.dataService
      .userlogin(angForm1.value.login, angForm1.value.password)
      .pipe()
      .subscribe(
        (data) => {
          if (this.returnUrl !== "/") {
            this.myurl = `${this.returnUrl}`;
          } else {
            this.myurl = `${"/dashboard/home"}`;
          }
          this.router.navigate([this.myurl]);

          // deconnexion du token
          setTimeout(() => {
            console.log('deconnexion');
            this.dataService.deleteToken();
            this.router.navigateByUrl("/pages/login-boxed");
            this.cookieService.deleteAll('/', '.dynaren.fr');
          }, 1000 * 60 * 60 * 24);

        },
        (error) => {
          alert("Login ou mot de passe incorrect");
        }
      );
  }
  get email() {
    return this.angForm.get("email");
  }
  get password() {
    return this.angForm.get("password");
  }
}
